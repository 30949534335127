@import '/src/theme/constants/colors';

.accordion {
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid #1e1e1e;

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      color: $white;
      // padding: 15px 0px;

      &-currencySymbolText {
        font-size: 11px !important;
      }

      &-item {
        font-size: 20px;
        line-height: 20px;
        margin-right: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        min-width: 13%;

        font-family: 'ProximaNova-Regular', sans-serif;
        color: rgba($white, 0.6);

        &:nth-child(3) {
          justify-content: flex-end !important;
          margin-right: 15px !important;
        }

        &:first-child {
          margin-right: 8px !important;
          width: 24px !important;
          height: 24px !important;
        }

        &-light {
          color: rgba($black, 0.6) !important;

          &:first-child {
            color: rgba($white, 1) !important;
          }
        }

        &-dark {
          color: rgba($white, 0.6) !important;

          &:first-child {
            color: rgba($white, 1) !important;
          }
        }

        > span {
          margin-left: 5px;
        }

        @media (max-width: 767px) {
          > span {
            display: none;
          }
        }

        @media (max-width: 769px) {
          margin-right: 14px;
          width: 20%;

          &:nth-child(2) {
            display: none;
          }
        }

        &-total {
          font-family: 'ProximaNova-Medium', sans-serif;
          color: rgba($white, 0.87);
          font-weight: 500;
          min-width: 30%;

          @media (max-width: 767px) {
            font-size: 16px;
          }
        }
        /* stylelint-disable */
        &:first-child {
          /* stylelint-enable */
          width: 24px;
          height: 24px;
          min-width: 24px;
          min-height: 24px;
          color: $white;
          border-radius: 4px;
          justify-content: center;
          align-items: center;
        }
      }

      &-icon {
        margin-right: 10px;
      }
    }
    &-arrow {
      justify-content: flex-end;
      font-size: 24px;
      color: $white;

      &-light {
        color: $black !important;
      }
    }
  }
}

.panel {
  padding: 0px;
  background-color: rgba($surface, 0.78);
  display: none;
  overflow: hidden;

  &-light {
    background-color: rgba($white, 0) !important;
  }
}
