@import '/src/theme/constants/colors';

.wrapper {
  display: flex;
  overflow-x: auto;
  overflow-y: auto;
  scroll-behavior: smooth;

  margin-top: 16px;
  user-select: none;

  &::-webkit-scrollbar {
    display: block;
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: #0f62fe;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(223, 223, 223, 0.34);
    border-radius: 12px;

    &::-webkit-scrollbar-thumb:hover {
      background: #5490ff;
    }
  }
}

.list-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  padding-bottom: 8px;
}
