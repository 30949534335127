@import '/src/theme/constants/colors';

.company-select-button {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  &-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    p {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
      text-align: center;
      padding: 0;
      margin: 0;
    }
  }

  abbr[title] {
    text-decoration: none;
  }

  &-light {
    color: rgba($black, 0.87) !important;
  }

  &-dark {
    color: rgba($white, 0.87) !important;
  }
}
