.downloadArea {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .downloadIcon {
    font-size: 20px !important;
  }

  .card {
    padding: 0px;
    padding-top: 16px;
    width: 100%;
    overflow: hidden;

    &-item {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    &-container {
      display: flex;
      justify-content: center;
    }

    &-right-item {
      font-family: 'ProximaNova-Regular', sans-serif;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.5px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      margin-right: 12px;

      cursor: pointer;

      > div {
        margin-left: 4px;
      }

      &-dark {
        color: #fff;

        &:hover {
          border-bottom: 1px solid #fff;
        }
      }
      &-light {
        color: rgb(68, 68, 68);

        &:hover {
          border-bottom: 1px solid rgb(68, 68, 68);
        }
      }
    }

    &-content {
      @media (max-width: 500px) {
        width: fit-content;
      }
    }
    &-title {
      padding-left: 12px;
    }
    &-subContent {
      font-family: 'ProximaNova-Regular', sans-serif;
      margin-top: 22px;
      padding-left: 12px;
      padding-right: 39px;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      cursor: pointer;
      user-select: none;

      &-dark {
        color: #fff;
      }
      &-light {
        color: rgb(68, 68, 68);
      }
      &-filter {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.5px;
        display: flex;
        align-items: center;
      }

      &-buttons {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.5px;
        display: flex;
        /*       div:first-child {
          border-right: 1px solid;
          margin-right: 10px;
          padding-right: 10px;
        } */
      }
    }

    &-download-loader {
      margin-right: 80px;
      margin-top: 10px;
    }
  }
}
