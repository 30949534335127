@import '/src/theme/constants/colors';

.icon {
  margin-left: 12px;
}

.account-wrapper {
  margin-top: 32px;
}

.bankAccount {
  font-size: 14px;
  min-width: 16%;
  background-color: transparent !important;
  @media (max-width: 500px) {
    font-size: 12px;
  }
}

.bankName {
  font-size: 14px;
  min-width: 25%;
  background-color: transparent !important;
  @media (max-width: 500px) {
    font-size: 12px;
  }
}

.balance {
  text-align: end;
  font-size: 14px;
  min-width: 16%;
  background-color: transparent !important;
  @media (max-width: 500px) {
    font-size: 12px;
  }
}

.branchName {
  font-size: 14px;
  min-width: 17%;
  background-color: transparent !important;
  @media (max-width: 500px) {
    font-size: 12px;
  }
}

.branchNo {
  font-size: 14px;
  min-width: 16%;
  background-color: transparent !important;
  @media (max-width: 500px) {
    font-size: 12px;
  }
}

.action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  min-width: 10%;
  color: #0f62fe;
  user-select: none;
  background-color: transparent !important;
  cursor: pointer;
  @media (max-width: 500px) {
    font-size: 12px;
  }

  &-icon {
    color: #0f62fe;
    margin-left: 4px;
    font-size: 18px;

    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
}

.card {
  padding: 0px;
  padding-top: 16px;
  width: 100%;
  overflow: hidden;

  &-container {
    display: flex;
    justify-content: center;
  }

  &-right-item {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    cursor: pointer;

    > div {
      margin-left: 4px;
    }

    &-dark {
      color: #fff;

      &:hover {
        border-bottom: 1px solid #fff;
      }
    }
    &-light {
      color: rgb(68, 68, 68);

      &:hover {
        border-bottom: 1px solid rgb(68, 68, 68);
      }
    }
  }
}

.table {
  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56px;
    margin: 16px 0 0;
    &-dark {
      background-color: rgba(255, 255, 255, 0.12);
    }
    &-light {
      background-color: rgba(30, 30, 30, 0.06);
    }

    &-cell {
      display: flex;
      align-items: center;
      &-dark {
        color: rgba(255, 255, 255, 0.87);
      }
      &-light {
        color: rgba(30, 30, 30, 0.87);
      }

      width: 20%;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.15px;
      &:first-child {
        padding-left: 55px;
      }
      &-text {
        display: flex;
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    &:last-child {
      margin-bottom: 35px;
    }
    &-row {
      cursor: pointer;
      width: 100%;
      height: 52px;
      display: flex;
      align-items: center;
      padding-left: 12px;
    }
    &-cell {
      &-closed {
        &:first-child {
          padding-left: 55px;
          position: relative;
          &:after {
            font-family: 'icomoon', sans-serif;
            content: '\e920';
            color: rgba(255, 255, 255, 0.6);
            font-size: 21px;
            position: absolute;
            left: 15px;
            width: 24px;
            border-left: 3px solid #fb8c00;
          }
        }
      }

      &-opened {
        &:first-child {
          padding-left: 55px;
          position: relative;
          &:before {
            position: absolute;
            left: 15px;
            content: '';
            width: 3px;
            background-color: #fb8c00;
            height: 28px;
            border-radius: 5px;
          }
        }
      }
      width: 20%;
      font-family: 'ProximaNova-Regular', sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.5px;
      &-dark {
        color: rgba(255, 255, 255, 0.87);
      }
      &-light {
        color: rgba(30, 30, 30, 0.66);
      }
    }
  }

  &-footer {
    display: flex;
    height: 56px;
    justify-content: space-between;
    font-family: 'ProximaNova-Regular', sans-serif;
    &-dark {
      color: white;
    }
    &-light {
      color: rgb(61, 61, 61);
    }

    &-page-size {
      padding-left: 24px;
      height: 100%;
      display: flex;
      align-items: center;
      width: 227px;
      b {
        margin-left: 5px;
      }
      border-right: 1px solid rgba(255, 255, 255, 0.12);
    }
    &-page-info {
      padding-left: 16px;
      height: 100%;
      display: flex;
      align-items: center;
      flex: 1;
      width: auto;
      min-width: 350px;
      border-right: 1px solid rgba(255, 255, 255, 0.12);
      span {
        margin-left: 10px;
      }
    }
    &-page-changer {
      padding-left: 13px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 269px;
      &-wrapper {
        display: flex;
      }
      &-button {
        cursor: pointer;
        &:first-child {
          border-right: 1px solid rgba(255, 255, 255, 0.12);
          border-left: 1px solid rgba(255, 255, 255, 0.12);
        }
        height: 56px;
        width: 56px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-disabled {
          cursor: default;
          opacity: 0.3;
        }
      }
    }
  }
}

.filters-select-currency {
  border-radius: 12px;
  margin-left: 8px;

  &-dark {
    background-color: rgba(255, 255, 255, 0.12);
  }

  &-light {
    background-color: rgba(30, 30, 30, 0.06);
  }

  @media (max-width: 1024px) {
    min-height: 46px;
  }
}

.filters-select-item {
  padding: 8px;
}
