.errorBoundary {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  background-color: #1e1e1e;
  color: rgb(237, 236, 236, 0.85);
  font-family: 'ProximaNova-Regular', sans-serif;

  &-link {
    text-decoration: none;
    font-weight: 600;
    color: rgb(237, 236, 236, 0.85);
    padding: 8px;
    border: 1px solid rgb(237, 236, 236, 0.85);
    border-radius: 4px;
    margin-bottom: 16px;
  }

  &-button {
    border: none;
    background-color: rgb(237, 236, 236, 0.85);
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
  }

  &-error {
    color: tomato;
  }
}
