@import '/src/theme/constants/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
}

.row-wrapper {
  padding-right: 16px;

  &-dark {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }

  &-light {
    border-bottom: 1px solid rgba(30, 30, 30, 0.12);
  }
}

.table {
  width: 100%;

  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin: 16px 0 0;
    padding-right: 16px;
    box-sizing: border-box;

    &-dark {
      background-color: rgba(255, 255, 255, 0.12);
    }

    &-light {
      background-color: rgba(30, 30, 30, 0.06);
    }

    &-cell {
      display: flex;
      align-items: center;

      &-dark {
        color: rgba(255, 255, 255, 0.87);
      }

      &-light {
        color: rgba(30, 30, 30, 0.87);
      }

      flex-grow: 1;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.15px;

      &:first-child {
        display: flex;
        justify-content: start;
        padding-left: 15px;
        width: 50px;
      }

      &-text {
        display: flex;
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: column;

    &:last-child {
      margin-bottom: 35px;
    }

    &-light {
      tr {
        border-bottom: 1px solid #d4d4d4;
      }

      &-row {
        background-color: #f7f7f7;
      }
    }

    &-dark {
      tr {
        border-bottom: 1px solid #333333;
      }

      &-row {
        background-color: #1a1a1a;
      }
    }

    &-row {
      cursor: pointer;
      width: 100%;
      height: 46px;
      display: flex;
      align-items: center;
    }

    &-lastTransactionDate {
      font-size: 10px;
      position: absolute;
      top: 18px;

      &-dark {
        color: rgba(255, 255, 255, 0.87);
      }

      &-light {
        color: rgba(30, 30, 30, 0.66);
      }
    }

    &-cell {
      &-checkbox {
        padding-left: 15px;
        width: 150px;
      }

      &-closed {
        &-dark {
          &:nth-child(2) {
            position: relative;
            //padding-left: 75px;

            &:after {
              font-family: 'icomoon', sans-serif;
              content: '\e920';
              color: rgba(255, 255, 255, 1);
              font-size: 21px;
              position: absolute;
              left: -30px;
              width: 24px;
              border-left: 3px solid #fb8c00;
            }
          }
        }

        &-light {
          &:nth-child(2) {
            position: relative;
            //padding-left: 75px;

            &:after {
              font-family: 'icomoon', sans-serif;
              content: '\e920';
              color: rgba(30, 30, 30, 1);
              font-size: 21px;
              position: absolute;
              left: -30px;
              width: 24px;
              border-left: 3px solid #fb8c00;
            }
          }
        }
      }

      &-opened {
        &-dark {
          &:nth-child(2) {
            //padding-left: 55px;
            position: relative;

            &:before {
              position: absolute;
              left: -30px;
              content: '';
              width: 3px;
              background-color: #fb8c00;
              height: 28px;
              border-radius: 5px;
            }
          }
        }

        &-light {
          &:nth-child(2) {
            //padding-left: 55px;
            position: relative;

            &:after {
              font-family: 'icomoon', sans-serif;
              content: '\e920';
              font-size: 21px;
              color: transparent;
              position: absolute;
              left: -30px;
              width: 24px;
              border-left: 3px solid #fb8c00;
            }
          }
        }
      }

      width: 20%;
      font-family: 'ProximaNova-Regular', sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.5px;

      &-dark {
        color: rgba(255, 255, 255, 0.87);
      }

      &-light {
        color: rgba(30, 30, 30, 0.66);
      }
    }

    &-footer {
      padding-left: 75px;
      display: flex;
      gap: 10px;

      &-cell {
        font-family: 'ProximaNova-Regular', sans-serif;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.5px;

        &-dark {
          color: #9fa8da;
        }

        &-light {
          color: #5c6bc0;
        }

        &-lastTransactionDate {
          text-transform: capitalize;
        }
      }
    }
  }

  &-footer {
    display: flex;
    height: 56px;
    justify-content: space-between;
    font-family: 'ProximaNova-Regular', sans-serif;

    &-dark {
      color: white;
    }

    &-light {
      color: rgb(61, 61, 61);
    }

    &-page-size {
      padding-left: 24px;
      height: 100%;
      display: flex;
      align-items: center;
      width: 227px;

      b {
        margin-left: 5px;
      }

      border-right: 1px solid rgba(255, 255, 255, 0.12);
    }

    &-page-info {
      padding-left: 16px;
      height: 100%;
      display: flex;
      align-items: center;
      flex: 1;
      width: auto;
      min-width: 350px;
      border-right: 1px solid rgba(255, 255, 255, 0.12);

      span {
        margin-left: 10px;
      }
    }

    &-page-changer {
      padding-left: 13px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 269px;

      &-wrapper {
        display: flex;
      }

      &-button {
        cursor: pointer;

        &:first-child {
          border-right: 1px solid rgba(255, 255, 255, 0.12);
          border-left: 1px solid rgba(255, 255, 255, 0.12);
        }

        height: 56px;
        width: 56px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-disabled {
          cursor: default;
          opacity: 0.3;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    max-width: 768px;
    overflow-x: auto;
  }

  .action {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    margin-left: 20px;
    font-size: 14px;
    width: 100px;
    color: #0f62fe;
    user-select: none;

    a,
    a:visited,
    a:hover,
    a:active {
      color: inherit;
      text-decoration: none;
    }

    .goDetailLink {
      width: 100px;
      display: flex;
      align-items: center;
    }

    cursor: pointer;

    @media (max-width: 500px) {
      font-size: 12px;
    }

    &-icon {
      color: #0f62fe;
      margin-left: 4px;
      font-size: 18px;

      @media (max-width: 500px) {
        font-size: 12px;
      }
    }
  }
}

.sub-table {
  margin-right: 20px;
  padding-left: 66px;
  position: relative;
  justify-content: center;

  font-family: 'ProximaNova-Regular', sans-serif;

  &:before {
    color: #fff;
    content: '\e920';
    font-family: 'icomoon', sans-serif;
    font-size: 21px;
    left: 25px;
    position: absolute;
    width: 24px;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
  }

  &-header {
    &-light {
      color: rgba(30, 30, 30, 0.66);
    }

    border-radius: 12px;

    &-cell {
      &:first-child {
        padding-left: 16px;
      }
    }
  }
}

.receipt {
  max-width: 100px;
  padding-left: 16px;

  &-button {
    width: 36px;
    height: 36px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    &-dark {
      background-color: rgba(255, 255, 255, 0.35);
    }

    &-light {
      color: white;
      background-color: rgba(30, 30, 30, 0.15);
    }
  }
}

.bankName {
  min-width: 250px;

  @media (max-width: 1024px) {
    min-width: 150px;
  }
}

.branchAndAccountNo {
  min-width: 150px;
}

.title {
  min-width: 150px;
}

.description {
  min-width: 250px;
  max-width: 250px;
  padding-right: 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: overlay;
}

.operation {
  max-width: 100px;
  display: flex;
  align-items: center;
}

.vkn {
  max-width: 120px;
}

.operationDate {
  max-width: 125px;
}

.filters-select-currency {
  border-radius: 12px;
  margin-left: 8px;

  &-dark {
    background-color: rgba(255, 255, 255, 0.12);
  }

  &-light {
    background-color: rgba(30, 30, 30, 0.06);
  }

  @media (max-width: 1024px) {
    min-height: 46px;
  }
}

.filters-select-item {
  padding: 8px;
}
