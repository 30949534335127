@import '/src/theme/constants/colors';

.bank-logo {
  img {
    height: 55px;
    object-fit: fill;
    width: 205px;
    align-self: flex-start;
  }
}

.bank {
  &-name {
    font-size: 24px;
    font-family: 'ProximaNova-Regular', sans-serif;

    &-dark {
      color: rgba($white, 0.6);
    }

    &-light {
      color: rgba($black, 0.6);
    }
  }
}
