@import '/src/theme/constants/colors';

.card {
  position: relative;
  height: auto;
  display: flex;
  flex: 1;
  flex-grow: 1;
  border-radius: 12px;
  padding: 16px;
  flex-direction: column;

  &:last-child {
    margin-right: 0;
  }

  &:only-child {
    margin-right: 0;
    width: -webkit-fill-available;
  }

  @media (max-width: 990px) {
    flex-grow: 0;
    margin-bottom: 8px;
  }

  &-dark {
    background-color: map-get($dark, 'card-background');
  }

  &-light {
    background-color: map-get($light, 'card-background');
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &-content {
    flex-direction: column;
    display: flex;
    height: 100%;
    // overflow: scroll;
  }
}

.title {
  font-family: 'ProximaNova-Medium', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.15px;
  padding: 8px 0px 0px 0px;

  &-dark {
    color: rgba($white, 0.87);
  }

  &-light {
    color: rgba($black, 0.87);
  }
}

.left-item {
  justify-content: flex-start;
}

.center-item {
  flex-grow: 1;
  justify-content: center;
}

.right-item {
  justify-content: flex-end;
}

.row {
  flex-direction: row;
  justify-content: flex-start;

  @media (max-width: 414px) {
    flex-direction: column;
  }
}

.column {
  flex-direction: column;
  justify-content: flex-start;
}

.wrap {
  flex-wrap: wrap;
}

.loader-container {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
}