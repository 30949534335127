@import '/src/theme/constants/colors.scss';

.switch {
  &-dark {
    border: 0.01px solid map-get($dark, 'theme-button-border');

    &:before {
      background-color: map-get($dark, 'theme-button');
    }
  }

  &-light {
    border: 0.01px solid map-get($light, 'theme-button-border');
    &:before {
      background-color: map-get($light, 'theme-button');
    }
  }
}

.icon {
  &-dark {
    border: 0.01px solid map-get($dark, 'theme-button-border');

    &:before {
      background-color: transparent;
      color: $white;
      font-size: 24px;
      left: 2px;
      bottom: 5px;
    }
  }

  &-light {
    border: 0.01px solid map-get($light, 'theme-button-border');

    &:before {
      background-color: transparent;
      color: $black;
      font-size: 24px;
      left: 2px;
      bottom: 5px;
    }
  }
}
