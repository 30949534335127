.wrapper {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin-right: 8px;

  > input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}
.checkbox {
  display: inline-block;
  width: 18px;
  height: 18px;

  border-radius: 3px;
  transition: all 150ms;

  &-dark {
    background-color: rgba(#fff, 0.12);
  }

  &-light {
    background-color: rgba(#1a1a1a, 0.12);
  }

  &-svg {
    fill: none;
    // stroke: white;
    stroke-width: 3px;
    margin-bottom: 2px;
  }

  &-checked {
    background-color: #0f62fe;

    &-dark {
      > svg {
        stroke: rgba(#1a1a1a, 1);
      }
    }

    &-light {
      > svg {
        stroke: rgba(#fff, 1);
      }
    }
  }
}
