@import '/src/theme/constants/colors';

.wrapper {
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-right: 16px;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 16px;
  user-select: none;
  font-family: 'ProximaNova-Medium', sans-serif;

  > p {
    font-size: 14px !important;
    letter-spacing: 0.1px;
    line-height: 1.5;
    margin: 0;
    text-align: center;
  }

  @media (max-width: 500px) {
    width: 100%;
    min-width: 50px;
    margin-right: 0px;
    margin-bottom: 16px;

    > p {
      font-size: 10px !important;
      text-align: center;
    }
  }

  &-light {
    background-color: map-get($light, 'sidebar-background');
    > p {
      color: rgba($black, 0.87) !important;
    }
  }

  &-dark {
    background-color: map-get($dark, 'sidebar-background');
    > p {
      color: rgba($white, 0.87) !important;
    }
  }

  &-loading {
    min-width: 200px;
    padding: 8px 0;
  }
}

.sidebar-select-company {
  min-width: 160px;
  width: 100%;
  height: 20px;
  justify-content: space-between;
  padding: 20px;

  &-placeholder {
    white-space: normal;
    line-height: 16px;
    max-width: 132px;
  }
}

.sidebar-select-company-container {
  max-height: 500px;
  overflow-y: auto;
  font-family: 'ProximaNova-Medium', sans-serif;
}

.option-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;

  height: 60px;

  font-size: 14px;

  padding: 8px;

  &:hover {
    background-color: rgba(rgb(77, 77, 77), 0.5);
  }

  &:first-child {
    font-size: 16px;
    border-radius: 12px 12px 0px 0px;

    > div {
      > div {
        text-align: center !important;
      }
    }
  }

  &:last-child {
    border-radius: 0px 0px 12px 12px;
  }

  &-selected {
    background-color: blue;
  }

  &-dark {
    background-color: map-get($dark, 'dropdown-option-background');
    border-bottom: 1px solid map-get($dark, 'dropdown-option-border');

    &:last-child {
      border-bottom: 0px solid map-get($dark, 'dropdown-option-border');
    }
  }

  &-light {
    background-color: map-get($light, 'dropdown-option-background');
    border-bottom: 1px solid map-get($light, 'dropdown-option-border');
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: 'ProximaNova-Medium', sans-serif;
    position: relative;

    &-left {
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      border-radius: 4px;
      padding: 8px 4px;
      margin-right: 8px;

      > span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
      }
    }

    &-right {
      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 2px;
        background-color: rgba(rgb(77, 77, 77), 0.25);
        z-index: 99;
      }
    }

    &-logo {
      height: 20px;
      width: 40px;
      margin-right: 8px;
    }

    &-dark {
      color: rgba(#fff, 0.87);
    }

    &-light {
      color: rgba(#1a1a1a, 0.87);
    }
  }

  &-sub {
    display: none;
    flex-direction: column;

    &-visible {
      display: flex;
    }

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 16px 8px 24px;

      /*       > div {
        padding: 8px 16px;
      } */

      &-dark {
        border-bottom: 1px solid map-get($dark, 'dropdown-option-border');
        color: map-get($dark, 'dropdown-option-color');

        &:last-child {
          border-bottom: 0px solid map-get($dark, 'dropdown-option-border');
        }
      }

      &-light {
        border-bottom: 1px solid map-get($light, 'dropdown-option-border');
        color: map-get($light, 'dropdown-option-color');

        &:last-child {
          border-bottom: 0px solid map-get($dark, 'dropdown-option-border');
        }
      }
    }
  }
}
