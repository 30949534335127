@import '/src/theme/constants/colors';

.card {
  height: min-content;
}

.balance {
  font-size: 14px;
  min-width: 20%;
  background-color: transparent !important;
  text-align: right;

  @media (max-width: 500px) {
    font-size: 12px;
  }
}

.branchName {
  font-size: 14px;
  min-width: 25%;
  margin-left: 15px;
  background-color: transparent !important;

  @media (max-width: 500px) {
    font-size: 12px;
  }
}

.branchNo {
  font-size: 14px;
  min-width: 30%;
  background-color: transparent !important;

  @media (max-width: 500px) {
    font-size: 12px;
  }
}

.bankAccountTitle {
  position: relative;
  width: 0px;
  min-width: 0px;
  background-color: transparent !important;
}

.bankAccountTitleSpan {
  position: absolute;
  right: 12px;
  margin-top: 8.68%;
  width: 140px;
  font-size: 11px;

  @media (max-width: 1400px) {
    right: 9px;
  }

  @media (max-width: 1300px) {
    right: 0px;
  }

  @media (max-width: 1200px) {
    right: 5px;
    margin-top: 7px;
    width: 116px;
    font-size: 11px;
  }

  @media (max-width: 1050px) {
    right: -9px;
    margin-top: 7px;
    width: 97px;
    font-size: 8px;
  }
}

.action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  min-width: 20%;
  color: #0f62fe;
  user-select: none;
  background-color: transparent !important;
  cursor: pointer;

  @media (max-width: 500px) {
    font-size: 12px;
  }

  &-icon {
    color: #0f62fe;
    margin-left: 4px;
    font-size: 18px;

    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
}

.accInfoContinue {
  font-size: 13px;
  padding: 3px 8px;
  background-color: blue;
  margin-right: 5px;
  color: rgb(255, 255, 255);
  border-radius: 20%;
}

.accInfoFinished {
  font-size: 13px;
  padding: 3px 8px;
  background-color: red;
  margin-right: 5px;
  color: rgb(255, 255, 255);
  border-radius: 20%;
}

.bankAccountsTableWrapper {
  max-height: 500px;
  overflow: auto;
}