@import '/src/theme/constants/colors';

.bank-account-card {
  flex: 0 0 auto;
  min-height: 410px;
  user-select: none;

  @media (max-width: 1200px) and (min-width: 769px) {
    width: 49%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  &-content {
    justify-content: flex-start;
  }

  &-arrow {
    cursor: pointer;
  }

  &-right-item-container {
    display: flex;
    gap: 8px;
  }

  &-balance {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 34px;
    line-height: 1.06;
    color: rgba($white, 0.87);
    margin-bottom: 5px;

    &-dark {
      color: rgba($white, 0.87);
    }

    &-light {
      color: rgba($black, 0.87);
    }

    @media (width: 768px) {
      margin-top: 8px;
    }
  }

  &-count {
    font-family: 'ProximaNova-Medium', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    letter-spacing: 0.1px;
    color: #f44336;
    margin-bottom: 29px;
  }

  &-info {
    font-family: 'ProximaNova-Medium', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.71;
    letter-spacing: 0.1px;
    color: #dd991b;
    margin-bottom: 10px;
  }

  &-list {
    &-title {
      font-family: 'ProximaNova-Regular', sans-serif;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.15px;
      color: rgba($white, 0.87);
      margin-bottom: 10px;
    }
  }
}

.currency-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &-symbol {
    width: 32px;
    height: 32px;
    border-radius: 12px;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    display: flex;

    > span {
      font-size: 22px;
      text-align: center;
      color: rgba($white, 0.9);
    }
  }

  &-text {
    font-family: 'ProximaNova-Medium', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-top: 4px;
  }
}
