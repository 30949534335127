@import '/src/theme/constants/colors.scss';

.filter-items {
  min-height: 46px;
  margin-right: 0px;
}

.lastItem {
  > div {
    padding: 6px 0 !important;
  }
}

.filter-input {
  display: flex;
  justify-content: space-between;

  &-item {
    margin-top: 16px;
    margin-bottom: 0;

    @media (max-width: 1024px) {
      flex: 1;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.second-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0;
  min-width: 230px;

  @media (max-width: 1024px) {
    margin-top: 16px;
  }
}

.basis-group {
  flex-basis: 30%;
}

.basis-group-banks {
  flex-basis: 22%;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  white-space: nowrap;
  column-gap: 15px;

  & button {
    width: 100%;
  }
}

.input-container {
  display: flex;
  column-gap: 10px;
}

.date-filter-item {
  max-width: inherit;
}

.table {
  width: 100%;

  &-body {
    display: flex;
    flex-direction: column;
    &:last-child {
      margin-bottom: 35px;
    }
    &-row {
      cursor: pointer;
      width: 100%;
      height: 46px;
      display: flex;
      align-items: center;
    }
    &-cell {
      &-closed {
        &-dark {
          &:first-child {
            position: relative;
            padding-left: 55px;
            &:after {
              font-family: 'icomoon', sans-serif;
              content: '\e920';
              color: rgba(255, 255, 255, 1);
              font-size: 21px;
              position: absolute;
              left: 15px;
              width: 24px;
              border-left: 3px solid #fb8c00;
            }
          }
        }

        &-light {
          &:first-child {
            position: relative;
            padding-left: 55px;
            &:after {
              font-family: 'icomoon', sans-serif;
              content: '\e920';
              color: rgba(30, 30, 30, 1);
              font-size: 21px;
              position: absolute;
              left: 15px;
              width: 24px;
              border-left: 3px solid #fb8c00;
            }
          }
        }
      }

      width: 20%;
      font-family: 'ProximaNova-Regular', sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.5px;
      &-dark {
        color: rgba(255, 255, 255, 0.87);
      }
      &-light {
        color: rgba(30, 30, 30, 0.66);
      }
    }
  }

  @media (max-width: 1024px) {
    max-width: 768px;
    overflow-x: auto;
  }
}

.card {
  display: flex;
  flex-direction: column;
  min-height: 56px !important;
  padding: 10px !important;
  width: 100%;

  &-selectItem {
    display: flex;
    justify-content: space-between;
  }

  &-item {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &-container {
    display: flex;
    justify-content: center;
  }

  &-content {
    @media (max-width: 500px) {
      width: fit-content;
    }
  }
  &-title {
    padding-left: 12px;
  }
  &-subContent {
    font-family: 'ProximaNova-Regular', sans-serif;
    margin-top: 22px;
    padding-left: 12px;
    padding-right: 39px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    cursor: pointer;
    user-select: none;

    &-dark {
      color: #fff;
    }
    &-light {
      color: rgb(68, 68, 68);
    }
    &-filter {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.5px;
      display: flex;
      align-items: center;
      margin-top: 10px;
    }

    &-buttons {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.5px;
      display: flex;
      /*       div:first-child {
        border-right: 1px solid;
        margin-right: 10px;
        padding-right: 10px;
      } */
    }
  }
}

.additionalFilters {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
