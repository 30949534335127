@import '/src/theme/constants/colors';

.table {
  width: 100%;
  border-collapse: collapse;
  border-color: transparent;
  text-align: center;
  &-header {
    width: 100%;

    &-dark {
      border-bottom: 2px solid #333333;
    }
    &-light {
      border-bottom: 2px solid #d4d4d4;
    }

    th {
      padding-bottom: 16px;
    }
  }

  &-cell {
    &-dark {
      color: rgba(255, 255, 255, 0.87);
    }
    &-light {
      color: rgba(30, 30, 30, 0.87);
    }

    padding: 2px 30px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.15px;

    &-text {
      padding: 2px 0;
      font-size: 14px;
    }

    &-success {
      background-color: #1a951a;
      padding: 4px 10px;
      border-radius: 4px;
      cursor: pointer;
    }

    &-error {
      background-color: #f44336;
      padding: 4px 10px;
      border-radius: 4px;
      cursor: pointer;
    }

    &-noData {
      padding: 20px;
      text-align: center;
    }

    &-refreshButton {
      width: 150px;
    }

    &-refreshButtonText {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &-wrapper {
      border-radius: 8px;
      padding: 12px 24px;

      &-dark {
        box-shadow: 0px 2px 8px 0px rgba(159, 159, 159, 0.36);
      }
      &-light {
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.18);
      }
    }

    &-textTotalBalance {
      font-weight: bold;
    }
  }

  &-body {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;

    &-dark {
      color: rgba(255, 255, 255, 0.87);

      tr {
        border-bottom: 1px solid #333333;
      }

      &-row {
        background-color: #1a1a1a;
      }
    }
    &-light {
      color: rgba(30, 30, 30, 0.66);

      tr {
        border-bottom: 1px solid #d4d4d4;
      }

      &-row {
        background-color: #f7f7f7;
      }
    }

    td {
      white-space: nowrap;
      padding: 12px 30px;
    }

    &-totalBalanceRow {
      background-color: #0f62fe3d;
    }
  }
}

.button-container-save {
  margin-top: 18px;
  padding-right: 2% !important;
  text-align: end;
  padding-bottom: 18px;
  border-bottom: 1px solid #d4d4d4;
}
.button-group-save {
  width: 150px;
}

.info {
  &-dark {
    color: rgba(255, 255, 255, 0.87);
    margin-right: 25% !important;
  }
  &-light {
    color: rgba(30, 30, 30, 0.87);
    margin-right: 25% !important;
  }
}
.loader-container {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
}
