@import '/src/theme/constants/colors';

.currency {
  display: flex;
  font-family: 'ProximaNova-Regular', sans-serif;
  font-size: 20px;
  width: 32px;
  height: 32px;
  min-width: 24px;
  min-height: 24px;
  color: $white;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
}

.symbol {
  &-smallFont {
    font-size: 14px;
  }

  &-mediumFont {
    font-size: 18px;
  }
}
