@import '/src/theme/constants/colors';

.semi-donut {
  --percentage: 0;
  --fill: $white;
  width: 220px;
  height: 100px;
  position: relative;
  font-size: 19px;
  font-weight: 600;
  overflow: hidden;
  color: var(--fill);
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  &:after {
    content: '';
    width: 220px;
    height: 220px;
    border: 10px solid;
    border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) var(--fill) var(--fill);
    position: absolute;
    border-radius: 50%;
    left: 0;
    top: 0;
    box-sizing: border-box;
    transform: rotate(calc(1deg * (-45 + var(--percentage) * 1.8)));
    animation: fillAnimation 1s ease-in;
  }
}

@keyframes fillAnimation {
  0% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(135deg);
  }
}

@keyframes fillGraphAnimation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
}
