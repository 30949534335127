@import '/src/theme/constants/colors.scss';

.dropdown {
  position: relative;
  margin: 6px 0;
  max-width: 150px;
}

.select-item {
  padding: 4px 0px 4px 8px;
}

.select-icon-wrapper {
  margin-left: 42px !important;
}

.transparent {
  > div {
    padding-top: 0px !important;
  }
}

.widget-dailySummary {
  max-width: max-content;
  margin: unset;
}

.card-wrapper {
  overflow-x: auto;
  transform: rotateX(180deg);

  &::-webkit-scrollbar {
    display: block;
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: #0f62fe;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(223, 223, 223, 0.34);
    border-radius: 12px;

    &::-webkit-scrollbar-thumb:hover {
      background: #5490ff;
    }
  }
}

.table {
  display: block;
  width: 100%;
  border-collapse: collapse;
  border-color: transparent;
  transform: rotateX(180deg);

  &-header {
    width: 100%;

    &-dark {
      border-bottom: 2px solid #333333;
    }
    &-light {
      border-bottom: 2px solid #d4d4d4;
    }

    th {
      padding-bottom: 16px;
    }
  }

  &-cell {
    &-dark {
      color: rgba(255, 255, 255, 0.87);
    }
    &-light {
      color: rgba(30, 30, 30, 0.87);
    }

    padding: 2px 30px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.15px;

    &-text {
      padding: 2px 0;
      font-size: 14px;
    }

    &-hasBalance {
      cursor: pointer;
      text-decoration: underline;
    }

    &-rightAlign {
      text-align: right;
    }

    &-noData {
      padding: 20px;
      text-align: center;
    }

    &-textBankName {
      white-space: nowrap;
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-wrapper {
      border-radius: 8px;
      padding: 12px 24px;

      &-dark {
        box-shadow: 0px 2px 8px 0px rgba(159, 159, 159, 0.36);
      }
      &-light {
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.18);
      }
    }

    &-textTotalBalance {
      font-weight: bold;
    }
  }

  &-body {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;

    &-dark {
      color: rgba(255, 255, 255, 0.87);

      tr {
        border-bottom: 1px solid #333333;
      }

      &-row {
        background-color: #1a1a1a;
      }
    }
    &-light {
      color: rgba(30, 30, 30, 0.66);

      tr {
        border-bottom: 1px solid #d4d4d4;
      }

      &-row {
        background-color: #f7f7f7;
      }
    }

    td {
      white-space: nowrap;
      padding: 12px 16px;
    }

    &-totalBalanceRow {
      background-color: #0f62fe3d;
    }
  }

  &-footer {
    display: block;
    width: 100%;

    &-row {
      display: block;
      width: 100%;

      td {
        border: none;
      }
    }
  }
}

.accountsModal {
  max-width: none !important;
  width: 90%;
  max-height: 80%;
  min-width: 300px !important;
  overflow-x: hidden;

  .modalFilterArea {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .checkbox {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 400px) {
    width: 90%;
  }
}
