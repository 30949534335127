@import '/src/theme/constants/colors';

.button-wrapper {
  border: 0px;
  outline: 0;
  cursor: pointer;
  border-radius: 8px;
  padding: 0px;
  user-select: none;

  &-dark {
    background-color: map-get($dark, 'button-background');
  }

  &-light {
    background-color: map-get($light, 'button-background');
  }

  &-transparent {
    background-color: transparent !important;
  }
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-family: 'ProximaNova-Medium', sans-serif;
  font-size: 16px;
  letter-spacing: 0.5px;
  border-radius: 8px;
  padding: 16px 16px;
  line-height: 24px;

  > * {
    font-family: 'ProximaNova-Medium', sans-serif;
  }

  &-dark {
    background-color: map-get($dark, 'button-background');
    color: map-get($dark, 'button-text');

    &:hover {
      background-color: map-get($dark, 'button-background-hover');
    }

    &:active {
      background-color: map-get($dark, 'button-background-active');
    }
  }

  &-light {
    background-color: map-get($light, 'button-background');
    color: map-get($light, 'button-text');

    &:hover {
      background-color: map-get($light, 'button-background-hover');
    }

    &:active {
      background-color: map-get($light, 'button-background-active');
    }
  }

  &-transparent {
    background-color: transparent !important;

    &-dark {
      color: map-get($dark, 'button-background-hover') !important;

      * {
        color: map-get($dark, 'button-background-hover') !important;
      }

      &:hover {
        * {
          color: map-get($dark, 'button-text') !important;
        }
      }

      &:active {
        * {
          color: map-get($dark, 'button-text') !important;
        }
      }
    }

    &-light {
      color: map-get($light, 'button-text') !important;
      /* stylelint-disable */
      * {
        color: map-get($light, 'button-text') !important;
      }
      /* stylelint-enable */
      &:hover {
        * {
          color: rgba(18, 18, 18, 1) !important;
        }
      }

      &:active {
        * {
          color: rgba(18, 18, 18, 1) !important;
        }
      }
    }
  }

  &-active {
    &-dark {
      color: map-get($dark, 'button-text') !important;
      /* stylelint-disable */
      * {
        color: map-get($dark, 'button-text') !important;
      }
      /* stylelint-enable */
      &:hover {
        * {
          color: map-get($dark, 'button-text') !important;
        }
      }

      &:active {
        * {
          color: map-get($dark, 'button-text') !important;
        }
      }
    }

    &-light {
      color: rgba(18, 18, 18, 1) !important;
      /* stylelint-disable */
      * {
        color: rgba(18, 18, 18, 1) !important;
      }
      /* stylelint-enable */
      &:hover {
        * {
          color: rgba(18, 18, 18, 1) !important;
        }
      }

      &:active {
        * {
          color: rgba(18, 18, 18, 1) !important;
        }
      }
    }
  }

  &-disable {
    &-dark {
      opacity: 0.4;
      background-color: map-get($dark, 'button-background-hover');

      &:hover {
        background-color: map-get($dark, 'button-background-hover');
      }

      &:active {
        background-color: map-get($dark, 'button-background-hover');
      }
    }

    &-light {
      opacity: 0.4;
      background-color: map-get($light, 'button-background-hover');

      &:hover {
        background-color: map-get($light, 'button-background-hover');
      }

      &:active {
        background-color: map-get($light, 'button-background-hover');
      }
    }
  }
}
