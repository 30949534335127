@import '/src/theme/constants/colors.scss';

.emptyMessage {
  background-color: white;
  padding: 30px 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 18px;

  font-family: 'ProximaNova-Medium', sans-serif;

  &-dark {
    background-color: #121212;
    color: white;
  }
  &-light {
    color: black;
  }
}

.select-area {
  font-family: 'ProximaNova-Medium', sans-serif;
  display: flex;
  span {
    margin-right: 15px;
  }
  &-dark {
    color: white;
  }
  &-light {
    color: black;
  }
}

.masonryWrapper {
  columns: 2 100px;
  column-gap: 1rem;
  .cardWrapper {
    width: 100%;
    margin: 0 1rem 1rem 0;
    break-inside: avoid;
  }
}

@media (max-width: 950px) {
  .masonryWrapper {
    columns: 1 100px;
    column-gap: 1rem;
    .cardWrapper {
      width: 100%;
      margin: 0 1rem 1rem 0;
      break-inside: avoid;
    }
  }
}
