@import '/src/theme/constants/colors.scss';

.wrapper {
  height: 100px;
}

.content {
  padding-top: 10%;
}

.title {
  padding: 20px 0px !important;
  text-align: center;
}

.button {
  padding: 20px 0px !important;
  background-color: #0f62fe;

  &:hover {
    background-color: rgba(#0f62fe, 0.8);
  }

  &-text {
    color: white;
  }
}

.error {
  color: $red2;
  text-align: center;
  margin: 16px 0px;
}

.rightItems {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;

  > div {
    margin-right: 8px;
  }
}

.logo {
  margin: 0 auto;
  padding: 16px 0px;

  > img {
    height: 64px;
  }
}
