@import '/src/theme/constants/colors';

.currency-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &-symbol {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-family: 'ProximaNova-Medium', sans-serif;

    > span {
      font-size: 22px;
      text-align: center;
      color: rgba($white, 0.9);
    }

    &-smallFont {
      span {
        font-size: 14px;
      }
    }

    &-mediumFont {
      span {
        font-size: 18px;
      }
    }
  }

  &-text {
    font-family: 'ProximaNova-Medium', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-top: 4px;
  }
}
