@import '/src/theme/constants/colors';

.select {
  &-container {
    outline: none;
    caret-color: rgb(190, 190, 190);
    color: rgb(85, 85, 85);
    display: flex;
    align-items: center;
    width: max-content;
    height: max-content;
    overflow-x: hidden;
    overflow-y: auto;
    cursor: pointer;
    padding: 4px 8px;
  }

  &-item {
    cursor: pointer;
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    padding: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    background-color: rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.9);
    white-space: nowrap;

    &-text {
      padding: 8px;

      &-light {
        color: #1e1e1e;
      }
    }

    &-active {
      & .select-item-text {
        background-color: #0f62fe !important;
        border-radius: 4px;
        color: white;
      }
    }

    &-selected {
      font-family: 'ProximaNova-Regular', sans-serif;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      color: rgba(255, 255, 255, 0.87);
      border-bottom: unset;
      padding: unset;
      display: inline-block;

      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &-light {
        color: #1e1e1e;
      }
    }

    &-container {
      list-style: none;
      position: absolute;
      display: inline-block;
      border-radius: 12px;
      margin: 0;
      padding: 0;
      opacity: 0;
      top: -100vw;
      visibility: hidden;
      z-index: 1001;
      overflow: auto;
      box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);

      &-dark {
        background-color: #121212;
      }

      &-light {
        background-color: #fff;
      }
    }

    &-arrowIcon {
      color: rgba(255, 255, 255, 0.87) !important;
      font-size: 24px;
      margin-left: 4px;

      &-light {
        color: #1e1e1e !important;
      }
    }

    &-light {
      border-bottom: 1px solid rgba(30, 30, 30, 0.12);
    }
  }
}

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  50% {
    visibility: visible;
    opacity: 0.1;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

.fade-in {
  animation: fade-in 200ms ease-in-out forwards;
}
