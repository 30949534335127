/* stylelint-disable */
.datepicker-dark {
  position: relative;
  border-radius: 12px;
  background-color: rgba(white, 0.12);
  display: flex;
  align-items: center;
  max-width: fit-content;
  cursor: pointer;
  user-select: none;
  min-width: 270px;
  font-family: 'ProximaNova-Regular', sans-serif;

  @media (max-width: 450px) {
    width: 100%;
    margin-bottom: 10px;
    max-width: initial;
  }

  .label {
    font-family: 'ProximaNova-Regular', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    color: rgba(white, 0.87);

    > span {
      letter-spacing: 0.65px;
      line-height: 1.5;
      color: rgba(white, 0.87);
    }

    > i {
      font-size: 24px;
      color: rgba(white, 0.87);
      margin-right: 8px;
    }

    .dates {
      margin-right: 24px;
    }

    .start-date,
    .end-date {
      font-family: 'ProximaNova-Regular', sans-serif;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0.5px;
    }

    .separator-date {
      margin: 0px 3px;
      color: rgba(white, 0.48);
    }

    .remove-dates {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 16px 16px 8px;
    }
  }
}

.datepicker-light {
  position: relative;
  border-radius: 12px;
  background-color: rgba(#1e1e1e, 0.06);
  display: flex;
  align-items: center;
  max-width: fit-content;
  cursor: pointer;
  user-select: none;
  min-width: 270px;

  @media (max-width: 450px) {
    width: 100%;
    margin-bottom: 10px;
    max-width: initial;
  }

  .label {
    font-family: 'ProximaNova-Regular', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    color: rgba(30, 30, 30, 0.66);
    > span {
      letter-spacing: 0.65px;
      line-height: 1.5;
      color: rgba(30, 30, 30, 0.66);
    }

    > i {
      font-size: 24px;
      color: rgba(30, 30, 30, 0.66);
      margin-right: 8px;
    }

    .dates {
      margin-right: 24px;
    }

    .start-date,
    .end-date {
      font-family: 'ProximaNova-Regular', sans-serif;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0.5px;
    }

    .separator-date {
      margin: 0px 3px;
      color: rgba(#1e1e1e, 0.48);
    }

    .remove-dates {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 16px 16px 8px;
    }
  }
}

.calendarInfo {
  padding-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.button-style {
  margin: 5px 10px;
  display: flex;
  justify-content: flex-start;
  button {
  }
}

.button-active {
  background-color: #0f62fe;
  color: #fff;

  &:hover {
    background-color: #003caa;
  }
}

.fast-button {
  padding: 4px 8px !important;
  min-width: 120px;
}
