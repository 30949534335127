.modal {
  &-title {
    font-size: 14px;
    font-weight: 600;

    &-dark {
      color: #b4bfbf;
    }

    &-light {
      color: #1e1e1ede;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    font-family: 'ProximaNova-Medium', sans-serif;
  }

  &-closeButton {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;
    opacity: 0.3;
    cursor: pointer;
    border: none;
    color: rgb(53, 53, 53);
  }

  &-container {
    z-index: 1000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 3px;
    min-width: 400px;
    max-width: 600px;
    max-height: 40%;
    padding: 10px;
    overflow: auto;

    &-dark {
      background-color: #0d0d0d;
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.25);
    }

    &-light {
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    }

    &::-webkit-scrollbar {
      display: block;
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 12px;
      background-color: #0f62fe;
    }

    &::-webkit-scrollbar-track {
      background-color: rgb(223, 223, 223, 0.34);
      border-radius: 12px;

      &::-webkit-scrollbar-thumb:hover {
        background: #5490ff;
      }
    }
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100vw;
    height: 100vh;

    &-dark {
      background-color: rgba(76, 76, 76, 0.5);
    }

    &-light {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &-content {
    font-size: 14px;
    font-family: 'ProximaNova-Medium', sans-serif;

    &-dark {
      color: #b4bfbf;
    }

    &-light {
      color: #1e1e1ede;
    }
  }

  &-lockBody {
    height: 100vh;
    overflow: hidden;
  }
}
