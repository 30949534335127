@import '/src/theme/constants/colors.scss';

.wrapper {
  height: 100px;
}

.content {
  padding-top: 10%;
  align-items: center;
  justify-content: center;
}
.main {
  align-items: center;
}

.verify {
  min-width: min-content;
  max-width: 570px;
  padding-left: 10%;
  padding-right: 10%;

  &-title {
    text-align: center;
    font-size: 34px;
    font-weight: bold;
  }
  &-address {
    margin-top: 16px;
    margin-bottom: 32px;
    text-align: center;
    font-size: 14px;
    font-weight: normal;
  }
  &-subtitle {
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    color: #42a5f5 !important;
  }
  &-resend-button {
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    color: #42a5f5 !important;
  }
  &-input-wrapper {
    margin-top: 16px;
    display: flex;

    input {
      margin-right: 8px;
      width: 56px;
      height: 56px;
    }
  }
}

.actions {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

.textButton {
  border-radius: 8.3px;
  width: 170px;
  padding: 22px 0;

  &-text {
    &-light {
      color: rgba(30, 30, 30, 0.66);
    }
    &-dark {
      color: white;
    }
  }

  &-dark {
    background-color: map-get($dark, 'card-background');
    &:hover {
      background-color: map-get($dark, 'card-background');
    }

    color: rgb(255, 255, 255);
  }

  &-light {
    background-color: map-get($light, 'card-background');
    &:hover {
      background-color: map-get($light, 'card-background');
    }
  }
}

.button {
  border-radius: 8.3px;
  width: 170px;
  padding: 22px 0;
  background-color: #0f62fe;
  &:hover {
    background-color: rgba(#0f62fe, 0.8);
  }
  &-text {
    color: white;

    &-light {
      color: rgba(30, 30, 30, 0.66);
    }
  }
}

.error {
  color: $red2;
  text-align: center;
  margin: 16px 0px;
}

.rightItems {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;

  > div {
    margin-right: 8px;
  }
}

.logo {
  margin: 0 auto;
  padding: 16px 0px;

  > img {
    height: 64px;
  }
}
