@use "sass:map";

$white: rgba(255, 255, 255, 0.9);
$black: #1e1e1e;
$primary: #0f62fe;
$secondary: #03dac5;
$surface: #121212;
$red: #cf6679;
$red2: #c52f2f;

$TRY: #fbc34e;
$EUR: #3d88c8;
$USD: #5ab466;
$GBP: #805ab4;
$CNY: #ff7043;
$CHF: #5ab3b4;
$CAD: #d7909e;
$RUB: #927f7a;
$JPY: #c7a062;
$YP: #d31d1d;
$DKK: #91a388;
$AED: #997679;
$SEK: #81806c;
$ZAR: #314892;
$KWD: #997679;

$light: (
  // Container
  background-color: #e8e8e8,
  // Header
  header-background: rgba($white, 0.9),
  // Button
  button: $white,
  button-text: rgba(30, 30, 30, 0.9),
  button-background: rgba(30, 30, 30, 0.04),
  button-background-hover: rgba(30, 30, 30, 0.09),
  button-background-active: rgba(30, 30, 30, 0.1),
  // Icon
  icon: rgba(18, 18, 18, 0.67),
  icon-hover: rgba(18, 18, 18, 0.87),
  // Card
  card-background: rgba($white, 0.9),
  // Input
  input-background: rgba(#1e1e1e, 0.04),
  input-text: rgba(#1e1e1e, 0.9),
  input-focus: rgba(#1e1e1e, 0.6),
  input-error: #cf6679,
  // Dropdown
  dropdown-background: rgba($black, 0.06),
  dropdown-text: rgba($black, 0.87),
  dropdown-icon: #121212,
  dropdown-option-shadow: rgba(0, 0, 0, 0.14),
  dropdown-option-background: #fff,
  dropdown-option-icon: $white,
  dropdown-option-select: rgba(#1e1e1e, 0.12),
  dropdown-option-select-hover: rgba(#1e1e1e, 0.06),
  dropdown-option-item-border: rgba($white, 0.12),
  dropdown-option-color: #1e1e1e,
  dropdown-option-selected: #0f62fe,
  dropdown-option-selected-background: rgba($white, 0.12),
  dropdown-option-selected-hover: rgba(#1e1e1e, 0.06),
  dropdown-option-border: rgba(#1e1e1e, 0.12),
  // Theme Switch
  theme-button: rgba($black, 1),
  theme-button-border: rgba($black, 0.48),
  //
  currency-box-background: rgba($white, 0.87),
  // Notications
  badge-color: #c52f2f,
  notification-list-border: rgba($black, 0.18),
  notification-arrow: rgba($black, 0.18),
  // Sidebar
  sidebar-background: rgba(255, 255, 255, 0.9),
  sidebar-item-color: rgba($black, 0.78)
);

$dark: (
  // Container
  background-color: #1e1e1e,
  // Header
  header-background: #121212,
  // Button
  button: #121212,
  button-text: rgba($white, 0.87),
  button-background: rgba($white, 0.12),
  button-background-hover: rgba($white, 0.24),
  button-background-active: rgba($white, 0.36),
  // Icon
  icon: rgba($white, 0.78),
  // Card
  card-background: #121212,
  // Input
  input-background: rgba($white, 0.04),
  input-text: rgba($white, 0.9),
  input-focus: rgba($white, 0.6),
  input-error: #c52f2f,
  // Dropdown
  dropdown-background: rgba($white, 0.12),
  dropdown-text: rgba($white, 0.87),
  dropdown-icon: rgba($white, 0.87),
  dropdown-option-shadow: rgba(255, 255, 255, 0.1),
  dropdown-option-background: #121212,
  dropdown-option-icon: rgba($white, 0.12),
  dropdown-option-select: $white,
  dropdown-option-select-hover: rgba($white, 0.06),
  dropdown-option-item-border: rgba($white, 0.12),
  dropdown-option-color: $white,
  dropdown-option-selected: #0f62fe,
  dropdown-option-selected-background: rgba($white, 0.12),
  dropdown-option-selected-hover: rgba($white, 0.24),
  dropdown-option-border: rgba($white, 0.12),
  // Theme Switch
  theme-button: rgba($white, 1),
  theme-button-border: rgba($white, 0.48),
  //
  currency-box-background: rgba($surface, 0.87),
  // Notications
  badge-color: #c52f2f,
  notification-list-border: rgba($white, 0.18),
  notification-arrow: rgba($white, 0.18),
  // Sidebar
  sidebar-background: $surface,
  sidebar-item-color: rgba($white, 0.78)
);
