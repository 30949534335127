@import '/src/theme/constants/colors.scss';

.wrapper {
  font-family: 'ProximaNova-Medium', sans-serif;
  height: 100px;
}

.content {
  padding-top: 10%;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
}

.buttonWrapperCenter {
  display: flex;
  justify-content: center;
}

.card {
  padding: 30px 50px !important;
}

.backButton {
  padding: 21px 27px !important;
  background-color: #121212 !important;
  &:hover {
    background-color: #121212 !important;
  }

  .button-text {
    color: rgba(255, 255, 255, 0.6);

    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
}

.submitButton {
  padding: 21px 27px !important;
  background-color: #0f62fe;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    background-color: rgba(#0f62fe, 0.8);
  }

  &-text {
    color: white;
  }
}

.cardTitle {
  font-size: 34px;
  margin-bottom: 16px;
  text-align: center;
}

.cardSubtitle {
  font-size: 14px;
  margin-bottom: 34px;
  text-align: center;
}

.error {
  color: $red2;
  text-align: center;
  margin: 16px 0px;
}

.rightItems {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;

  > div {
    margin-right: 8px;
  }
}

.logo {
  margin: 0 auto;
  padding: 16px 0px;

  > img {
    height: 64px;
  }
}
