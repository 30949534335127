@import '/src/theme/constants/colors';

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 58px;
  height: 24px;
  // margin-right: 16px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 1;
  width: 0;
  height: 0;
  visibility: hidden;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 2px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.checked:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

/* Rounded sliders */
.round {
  border-radius: 30px;
}

.round:before {
  border-radius: 50%;
}
