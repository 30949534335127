@import '/src/theme/constants/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
.row-wrapper {
  &-dark {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }
  &-light {
    border-bottom: 1px solid rgba(30, 30, 30, 0.12);
  }
}

.table {
  &-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 56px;
    margin: 16px 0 0;
    &-dark {
      background-color: rgba(255, 255, 255, 0.12);
    }
    &-light {
      background-color: rgba(30, 30, 30, 0.06);
    }

    &-cell {
      display: flex;
      align-items: center;
      &-dark {
        color: rgba(255, 255, 255, 0.87);
      }
      &-light {
        color: rgba(30, 30, 30, 0.87);
      }

      width: 20%;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.15px;
      &:first-child {
        padding-left: 55px;
      }
      &-text {
        display: flex;
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    &:last-child {
      margin-bottom: 35px;
    }
    &-row {
      cursor: pointer;
      //width: 100%;
      height: 52px;
      display: flex;
      align-items: center;
      padding-left: 12px;
    }
    &-cell {
      &-closed {
        &:first-child {
          padding-left: 55px;
          position: relative;
          &:after {
            font-family: 'icomoon', sans-serif;
            content: '\e920';
            color: rgba(255, 255, 255, 0.6);
            font-size: 21px;
            position: absolute;
            left: 15px;
            width: 24px;
            border-left: 3px solid #fb8c00;
          }
        }
      }

      &-opened {
        &:first-child {
          padding-left: 55px;
          position: relative;
          &:before {
            position: absolute;
            left: 15px;
            content: '';
            width: 3px;
            background-color: #fb8c00;
            height: 28px;
            border-radius: 5px;
          }
        }
      }
      width: 20%;
      font-family: 'ProximaNova-Regular', sans-serif;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.5px;
      &-dark {
        color: rgba(255, 255, 255, 0.87);
      }
      &-light {
        color: rgba(30, 30, 30, 0.66);
      }
    }
  }

  &-footer {
    display: flex;
    height: 56px;
    justify-content: space-between;
    font-family: 'ProximaNova-Regular', sans-serif;
    &-dark {
      color: white;
    }
    &-light {
      color: rgb(61, 61, 61);
    }

    &-page-size {
      padding-left: 24px;
      height: 100%;
      display: flex;
      align-items: center;
      width: 227px;
      b {
        margin-left: 5px;
      }
      border-right: 1px solid rgba(255, 255, 255, 0.12);
    }
    &-page-info {
      padding-left: 16px;
      height: 100%;
      display: flex;
      align-items: center;
      flex: 1;
      width: auto;
      min-width: 350px;
      border-right: 1px solid rgba(255, 255, 255, 0.12);
      span {
        margin-left: 10px;
      }
    }
    &-page-changer {
      padding-left: 13px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 269px;
      &-wrapper {
        display: flex;
      }
      &-button {
        cursor: pointer;
        &:first-child {
          border-right: 1px solid rgba(255, 255, 255, 0.12);
          border-left: 1px solid rgba(255, 255, 255, 0.12);
        }
        height: 56px;
        width: 56px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-disabled {
          cursor: default;
          opacity: 0.3;
        }
      }
    }
  }
}

.sub-table {
  margin-right: 20px;
  padding-left: 60px;
  position: relative;
  justify-content: center;

  font-family: 'ProximaNova-Regular', sans-serif;

  &-extented {
    padding-left: 30px;
    //width: 1000px;
    overflow-x: auto;
    height: 100%;
  }

  &-dark {
    &:before {
      color: #fff;
      content: '\e920';
      font-family: 'icomoon', sans-serif;
      font-size: 21px;
      left: 25px;
      position: absolute;
      width: 24px;
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
    }
  }

  &-light {
    &:before {
      color: rgba(30, 30, 30, 1);
      content: '\e920';
      font-family: 'icomoon', sans-serif;
      font-size: 21px;
      left: 25px;
      position: absolute;
      width: 24px;
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
    }
  }

  &-header {
    border-radius: 12px;
    padding-left: 12px;

    &-light {
      color: rgba(30, 30, 30, 0.66);
    }

    &-cell {
      &:first-child {
        padding-left: 16px;
      }
    }
  }

  @media (max-width: 1024px) {
    &-header {
      width: 1000px;
    }
    &-body {
      width: 1000px;
    }
  }
}
.receipt-select {
  max-width: 40px;
}
.receipt {
  max-width: 65px;
  padding-left: 5px;

  &-button {
    width: 36px;
    height: 36px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    &-dark {
      background-color: rgba(255, 255, 255, 0.35);
    }
    &-light {
      color: white;
      background-color: rgba(30, 30, 30, 0.15);
    }
  }
}
.bankName {
  min-width: 250px;
  font-size: 14px;
}
.description {
  font-size: 14px;
  min-width: 250px;
  max-width: 250px;
  padding-right: 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: overlay;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    overflow: hidden;
  }
}
.accountNo {
  font-size: 14px;
  min-width: 150px;
  max-width: 250px;
}
.branchCode {
  font-size: 14px;
  min-width: 100px;
  max-width: 250px;
}
.operation {
  font-size: 14px;
  max-width: 100px;
  display: flex;
  align-items: center;

  &-margin {
    margin-left: -12px;
  }
}
.vkn {
  font-size: 14px;
  max-width: 120px;
}
.operationDate {
  font-size: 14px;
  max-width: 125px;
}

.emptyView {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'ProximaNova-Regular', sans-serif;
  color: #fff;
  font-size: 16px;
  padding: 16px;
  margin-top: 8px;
}

.header-checkbox {
  margin-bottom: 5px;
}

.filters-select-currency {
  border-radius: 12px;
  margin-left: 8px;

  &-dark {
    background-color: rgba(255, 255, 255, 0.12);
  }

  &-light {
    background-color: rgba(30, 30, 30, 0.06);
  }

  @media (max-width: 1024px) {
    min-height: 46px;
  }
}

.filters-select-item {
  padding: 8px;
}
