@import '/src/theme/constants/colors';

.dropdown {
  position: relative;
  max-width: initial;
  cursor: pointer;
  user-select: none;
  margin-right: 10px;
  font-family: 'ProximaNova-Regular', sans-serif;
  outline: 0;
  height: 100%;

  @media (max-width: 450px) {
    min-height: 56px;
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px !important;
  }

  &:active {
    background-color: transparent;
  }

  &-transparent {
    position: relative;
  }
}

.select {
  border-radius: 12px;
  /*   padding: 4px 8px 4px 8px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;

  max-width: inherit;
  height: 100%;

  @media (max-width: 450px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px !important;
    min-height: 56px;
  }

  &:active {
    background-color: transparent;
  }

  &-light {
    background-color: map-get($light, 'dropdown-background') !important;

    > span {
      color: map-get($light, 'dropdown-text') !important;
    }
  }

  &-dark {
    background-color: map-get($dark, 'dropdown-background') !important;

    > span {
      color: map-get($dark, 'dropdown-text') !important;
    }
  }

  &-transparent {
    background-color: transparent !important;
    border-radius: 0px !important;
    padding: 13px 8px 0px 16px;

    &-light {
      border-bottom: 3px solid map-get($light, 'dropdown-background');
    }

    &-dark {
      border-bottom: 3px solid map-get($dark, 'dropdown-background');
    }
  }

  @media (max-width: 450px) {
    width: 100%;
    max-width: initial;
    box-sizing: border-box;
  }

  @media (max-width: 990px) {
    max-width: inherit;
    box-sizing: border-box;
  }

  > span {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: map-get($dark, 'dropdown-text');
  }

  .placeholder {
    visibility: visible;
    padding-left: 16px;

    &-hidden {
      visibility: hidden;
    }
  }

  .value {
    position: absolute;
    left: 16px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .value {
      bottom: 8px;
    }
  }

  .values {
    position: absolute;
    left: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &-dark {
      color: rgba(white, 0.87);
    }

    &-light {
      color: rgba(#1a1a1a, 0.87);
    }

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 4px;
      padding: 4px 8px;
      border: 2px solid map-get($dark, 'dropdown-option-item-border');
      border-radius: 4px;

      &-text {
        margin-right: 4px;
      }
    }
  }

  .icon-wrapper {
    display: flex;
    padding: 4px 8px;
    color: map-get($light, 'dropdown-icon');
    justify-content: center;
    align-items: center;
    z-index: 1;

    @media (max-width: 990px) {
      margin-left: 20px;
    }

    > i {
      font-size: 24px;
    }

    &-dark {
      > i {
        color: map-get($dark, 'dropdown-icon') !important;
      }
    }

    &-light {
      > i {
        color: map-get($light, 'dropdown-icon') !important;
      }
    }
  }
}

.options {
  display: none;
  position: absolute;

  max-height: 400px;
  overflow-y: scroll;
  right: 0;
  left: 0;
  flex-direction: column;
  border-radius: 12px;
  user-select: none;
  z-index: 99;
  margin-top: 5px;
  box-shadow: 0 16px 24px 2px map-get($light, 'dropdown-option-shadow');

  &-visible {
    display: flex;
  }

  &-dark {
    background-color: map-get($dark, 'dropdown-option-background');
  }

  &-light {
    background-color: map-get($light, 'dropdown-option-background');
  }
}

.option {
  padding: 16px 16px;

  > div {
    padding: 8px;
  }

  &:first-child {
    border-radius: 12px 12px 0px 0px;
  }

  &:last-child {
    border-bottom: 0px;
    border-radius: 0px 0px 12px 12px;
  }

  &-selected {
    > div {
      background-color: map-get($light, 'dropdown-option-selected') !important;
      color: white;
      border-radius: 4px;
    }
  }

  &-dark {
    border-bottom: 1px solid map-get($dark, 'dropdown-option-border');
    background-color: map-get($dark, 'dropdown-option-selected-background');
    color: map-get($dark, 'dropdown-option-color');

    &:hover {
      background-color: map-get($dark, 'dropdown-option-selected-hover');
    }
  }

  &-light {
    border-bottom: 1px solid map-get($light, 'dropdown-option-border');
    background-color: map-get($light, 'dropdown-option-selected-background');
    color: map-get($light, 'dropdown-option-color');

    &:hover {
      background-color: map-get($light, 'dropdown-option-selected-hover');
    }
  }
}

.options-input {
  margin: 6px 0 0 0 !important;
}
