@import '/src/theme/constants/colors.scss';

#page {
  display: flex;
  width: 100%;
  min-height: 100vh;

  > div {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }
}

.theme-dark {
  background-color: map-get($dark, 'background-color');
}

.theme-light {
  background-color: map-get($light, 'background-color');
}
