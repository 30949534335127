@import '/src/theme/constants/colors.scss';

.content {
  display: grid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  //background: white;
}

.bodycontent {
  position: relative;
  body {
    background-color: #fff !important;
    //background: white !important;
  }
}

.header {
  margin-top: 30px;
  height: 60px;
  display: flex;
  padding-left: 16px;
  justify-content: center;
  align-items: center;
  font-family: 'ProximaNova-Medium', sans-serif;
  font-size: 16px;
  color: #121212;
  position: relative;
  background-color: rgba(30, 30, 30, 0.15);
  // border-radius: 0px 0px 8px 8px;
  border-bottom: 1px solid rgba(221, 221, 221, 1);
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: 50px;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  padding: 8px 0px;
  margin-top: 8px;
  margin-bottom: 8px;
  justify-content: center;
  align-items: center;
  //border-top: 1px solid rgba(221, 221, 221, 1);
  // border-bottom: 1px solid rgba(221, 221, 221, 1);
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    position: absolute;
    width: 100%;
    top: 150px;
  }
}

.close {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  cursor: pointer;

  > i {
    font-size: 24px;
  }
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0px 16px;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 4px;
  font-family: 'ProximaNova-Medium', sans-serif;
  font-size: 13px;
  color: #1a1a1a;

  &:hover {
    background-color: rgba(221, 221, 221, 0.7);
  }

  > img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}

.line {
  width: 1px;
  background-color: #1a1a1a;
  height: 28px;
  margin: 0px 4px;
}

#receipt {
  padding: 8px 8px;
}
