@import '/src/theme/constants/colors';

.button {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 0 4px 10px;
  padding: 6px 12px 6px 6px;
  border-radius: 32px;

  user-select: none;
  cursor: pointer;

  outline: 0;

  ::-moz-selection {
    background: transparent;
  }
  ::selection {
    background: transparent;
  }

  &-dark {
    background-color: rgba($white, 0.12);
  }

  &-light {
    background-color: rgba($black, 0.12);
  }

  &-active {
    background-color: rgba(#0f62fe, 1) !important;
    color: rgba($white, 0.87) !important;
  }

  &-icon {
    display: inline-flex;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin: 0 8px 0 0;

    &-dark {
      background-color: rgba($white, 0.9);
      color: $black;
    }

    &-light {
      background-color: rgba($white, 0.9);
      color: $black;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      padding: 2px;
    }
  }

  &-text {
    font-size: 12px;
    font-family: Roboto, sans-serif;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba($white, 0.87);

    &-dark {
      color: rgba($white, 0.87);
    }

    &-light {
      color: rgba($black, 0.87);
    }

    &-active {
      color: rgba($white, 0.87) !important;
    }
  }
}
