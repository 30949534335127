@font-face {
  font-family: 'ProximaNova-Medium';
  src: url('assets/fonts/ProximaNova-Medium/ProximaNova-Medium.eot');
  src: url('assets/fonts/ProximaNova-Medium/ProximaNova-Medium.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/ProximaNova-Medium/ProximaNova-Medium.woff2') format('woff2'),
    url('assets/fonts/ProximaNova-Medium/ProximaNova-Medium.woff') format('woff'),
    url('assets/fonts/ProximaNova-Medium/ProximaNova-Medium.ttf') format('truetype'),
    url('assets/fonts/ProximaNova-Medium/ProximaNova-Medium.svg') format('svg');
}

@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('assets/fonts/ProximaNova-Regular/ProximaNova-Regular.eot');
  src: url('assets/fonts/ProximaNova-Regular/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/ProximaNova-Regular/ProximaNova-Regular.woff2') format('woff2'),
    url('assets/fonts/ProximaNova-Regular/ProximaNova-Regular.woff') format('woff'),
    url('assets/fonts/ProximaNova-Regular/ProximaNova-Regular.ttf') format('truetype'),
    url('assets/fonts/ProximaNova-Regular/ProximaNova-Regular.svg') format('svg');
}

@font-face {
  font-family: 'ProximaNova-Light';
  src: url('assets/fonts/ProximaNova-Light/ProximaNova-Light.eot');
  src: url('assets/fonts/ProximaNova-Light/ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/ProximaNova-Light/ProximaNova-Light.woff2') format('woff2'),
    url('assets/fonts/ProximaNova-Light/ProximaNova-Light.woff') format('woff'),
    url('assets/fonts/ProximaNova-Light/ProximaNova-Light.ttf') format('truetype'),
    url('assets/fonts/ProximaNova-Light/ProximaNova-Light.svg') format('svg');
}
