@import '/src/theme/constants/colors';

.header-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.branchName {
  width: 25%;
  min-width: 245px;
  text-align: center;
  justify-content: center;
  font-size: 14px;
}

.companyName {
  width: 12%;
  min-width: 140px;
  text-align: center;
  justify-content: center;
  font-size: 14px;
}

.branchAndAccountNo {
  width: 20%;
  min-width: 140px;
  text-align: center;
  justify-content: center;
  font-size: 14px;
}

.branchNo {
  width: 12%;
  min-width: 140px;
  text-align: center;
  justify-content: center;
  font-size: 14px;
}

.iban {
  width: 24%;
  min-width: 260px;
  text-align: center;
  justify-content: center;
  font-size: 14px;
}

.currency {
  width: 12%;
  min-width: 110px;
  text-align: center;
  justify-content: center;
  font-size: 14px;
}

.balance {
  font-size: 14px;
  width: 15%;
  min-width: 130px;
  text-align: right;
  justify-content: flex-end;
}

.table {
  padding: 16px 0px;
}

.action {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  margin-right: 8px;
  font-size: 14px;
  width: 110px;
  color: #0f62fe;
  user-select: none;
  cursor: pointer;
  @media (max-width: 500px) {
    font-size: 12px;
  }

  &-icon {
    color: #0f62fe;
    margin-left: 4px;
    font-size: 18px;

    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
}
