@import '/src/theme/constants/colors';

.wrapper {
  position: relative;
  margin: 16px 0px;

  button {
    position: absolute;
    border: 0;
    width: 22px;
    height: 100%;
    right: 16px;
    top: 0;
    z-index: 99;
    background-color: transparent;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.input {
  font-family: 'ProximaNova-Regular', sans-serif;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 0;
  line-height: 24px;

  &:focus {
    border: 0;
    box-shadow: none;
    outline: 0;
  }

  &-dark {
    background-color: map-get($dark, 'input-background');
    color: map-get($dark, 'input-text') !important;

    &:focus {
      border-bottom: 2px solid map-get($dark, 'input-focus');
      border-radius: 4px 4px 0px 0px;
    }
  }

  &-light {
    background-color: map-get($light, 'input-background');
    color: map-get($light, 'input-text') !important;

    &:focus {
      border-bottom: 2px solid map-get($light, 'input-focus');
      border-radius: 4px 4px 0px 0px;
    }
  }

  &-error {
    border-bottom: 2px solid map-get($light, 'input-error');
    border-radius: 4px 4px 0px 0px;
  }

  &-icon {
    padding-left: 52px;
  }

  &-mini-box {
    width: 56px;
    height: 56px;
    text-align: center;
  }
}

.icon {
  position: absolute;
  left: 16px;
  top: 30%;
  font-size: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
}
