.header-select-currency {
  border-bottom: 3px solid rgba(255, 255, 255, 0.12);

  &-light {
    border-bottom: 3px solid rgba(30, 30, 30, 0.06);
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: unset;
    padding: 12px;
  }
}

.header-select-currency-container {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
