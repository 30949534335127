@import '/src/theme/constants/colors.scss';

.table-cell-warning {
  background-color: #ff9800;
}

.dropdown {
  position: relative;
  margin: 6px 0;
  max-width: 150px;
}

.select-item {
  padding: 4px 0px 4px 8px;
}

.select-icon-wrapper {
  margin-left: 42px !important;
}

.transparent {
  > div {
    padding-top: 0px !important;
  }
}

.widget-companyAccounts {
  max-width: max-content;
  margin: unset;
}

.card-wrapper {
  overflow-x: auto;
  transform: rotateX(180deg);

  &::-webkit-scrollbar {
    display: block;
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: #0f62fe;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(223, 223, 223, 0.34);
    border-radius: 12px;

    &::-webkit-scrollbar-thumb:hover {
      background: #5490ff;
    }
  }
}

.table {
  display: block;
  width: 100%;
  border-collapse: collapse;
  border-color: transparent;
  transform: rotateX(180deg);

  &-header {
    width: 100%;

    &-dark {
      border-bottom: 2px solid #333333;
    }
    &-light {
      border-bottom: 2px solid #d4d4d4;
    }

    th {
      padding-bottom: 16px;
    }
  }

  &-cell {
    &-dark {
      color: rgba(255, 255, 255, 0.87);
    }
    &-light {
      color: rgba(30, 30, 30, 0.87);
    }

    padding: 2px 30px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.15px;

    &-text {
      padding: 2px 0;
      font-size: 14px;
    }

    &-success {
      background-color: #1a951a !important;
      padding: 4px 10px;
      border-radius: 4px;
      cursor: pointer;
    }

    &-error {
      background-color: #f44336;
      padding: 4px 10px;
      border-radius: 4px;
      cursor: pointer;
    }

    &-noData {
      padding: 20px;
      text-align: center;
    }

    &-refreshButton {
      width: 150px;
    }

    &-refreshButtonText {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &-wrapper {
      border-radius: 8px;
      padding: 12px 24px;

      &-dark {
        box-shadow: 0px 2px 8px 0px rgba(159, 159, 159, 0.36);
      }
      &-light {
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.18);
      }
    }

    &-textTotalBalance {
      font-weight: bold;
    }

    &-plaidUid {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &-body {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;

    &-dark {
      color: rgba(255, 255, 255, 0.87);

      tr {
        border-bottom: 1px solid #333333;
      }

      &-row {
        background-color: #1a1a1a;
      }
    }
    &-light {
      color: rgba(30, 30, 30, 0.66);

      tr {
        border-bottom: 1px solid #d4d4d4;
      }

      &-row {
        background-color: #f7f7f7;
      }
    }

    td {
      white-space: nowrap;
      padding: 12px 30px;
    }

    &-totalBalanceRow {
      background-color: #0f62fe3d;
    }
  }

  &-footer {
    display: block;
    width: 100%;

    &-row {
      display: block;
      width: 100%;

      td {
        border: none;
      }
    }

    &-rowSelect {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.table-page {
  width: 100%;
  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin: 16px 0 0;
    padding-right: 16px;
    box-sizing: border-box;
    &-dark {
      background-color: rgba(255, 255, 255, 0.12);
    }
    &-light {
      background-color: rgba(30, 30, 30, 0.06);
    }

    &-cell {
      display: flex;
      align-items: center;
      &-dark {
        color: rgba(255, 255, 255, 0.87);
      }
      &-light {
        color: rgba(30, 30, 30, 0.87);
      }

      flex-grow: 1;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.15px;
      &:first-child {
        padding-left: 55px;
      }
      &-text {
        display: flex;
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    &:last-child {
      margin-bottom: 35px;
    }
    &-row {
      cursor: pointer;
      width: 100%;
      height: 46px;
      display: flex;
      align-items: center;
    }
    &-lastTransactionDate {
      font-size: 10px;
      position: absolute;
      top: 18px;
      &-dark {
        color: rgba(255, 255, 255, 0.87);
      }
      &-light {
        color: rgba(30, 30, 30, 0.66);
      }
    }
    &-cell {
      &-closed {
        &-dark {
          &:first-child {
            position: relative;
            padding-left: 55px;
            &:after {
              font-family: 'icomoon', sans-serif;
              content: '\e920';
              color: rgba(255, 255, 255, 1);
              font-size: 21px;
              position: absolute;
              left: 15px;
              width: 24px;
              border-left: 3px solid #fb8c00;
            }
          }
        }

        &-light {
          &:first-child {
            position: relative;
            padding-left: 55px;
            &:after {
              font-family: 'icomoon', sans-serif;
              content: '\e920';
              color: rgba(30, 30, 30, 1);
              font-size: 21px;
              position: absolute;
              left: 15px;
              width: 24px;
              border-left: 3px solid #fb8c00;
            }
          }
        }
      }

      &-opened {
        &-dark {
          &:first-child {
            padding-left: 55px;
            position: relative;
            &:before {
              position: absolute;
              left: 15px;
              content: '';
              width: 3px;
              background-color: #fb8c00;
              height: 28px;
              border-radius: 5px;
            }
          }
        }

        &-light {
          &:first-child {
            padding-left: 55px;
            position: relative;
            &:after {
              font-family: 'icomoon', sans-serif;
              content: '\e920';
              font-size: 21px;
              color: transparent;
              position: absolute;
              left: 15px;
              width: 24px;
              border-left: 3px solid #fb8c00;
            }
          }
        }
      }
      width: 20%;
      font-family: 'ProximaNova-Regular', sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.5px;
      &-dark {
        color: rgba(255, 255, 255, 0.87);
      }
      &-light {
        color: rgba(30, 30, 30, 0.66);
      }
    }
  }

  &-footer {
    display: flex;
    height: 56px;
    justify-content: space-between;
    font-family: 'ProximaNova-Regular', sans-serif;
    &-dark {
      color: white;
    }
    &-light {
      color: rgb(61, 61, 61);
    }

    &-page-size {
      padding-left: 24px;
      height: 100%;
      display: flex;
      align-items: center;
      width: 227px;
      b {
        margin-left: 5px;
      }
      border-right: 1px solid rgba(255, 255, 255, 0.12);
    }
    &-page-info {
      padding-left: 16px;
      height: 100%;
      display: flex;
      align-items: center;
      flex: 1;
      width: auto;
      min-width: 350px;
      border-right: 1px solid rgba(255, 255, 255, 0.12);
      span {
        margin-left: 10px;
      }
    }
    &-page-changer {
      padding-left: 13px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 269px;
      &-wrapper {
        display: flex;
      }
      &-button {
        cursor: pointer;
        &:first-child {
          border-right: 1px solid rgba(255, 255, 255, 0.12);
          border-left: 1px solid rgba(255, 255, 255, 0.12);
        }
        height: 56px;
        width: 56px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-disabled {
          cursor: default;
          opacity: 0.3;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    max-width: 768px;
    overflow-x: auto;
  }
}

.filters-select-currency {
  border-radius: 12px;
  margin-left: 8px;

  &-dark {
    background-color: rgba(255, 255, 255, 0.12);
  }

  &-light {
    background-color: rgba(30, 30, 30, 0.06);
  }

  @media (max-width: 1024px) {
    min-height: 46px;
  }
}

.company-account-refresh-button {
  padding: 4px 5px 8px 8px;
  font-size: 36px;
}

.companyAccounts-select {
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 12px;
  padding: 4px 12px;
  margin: 4px 0;

  &-container {
    margin-top: 4px;
    max-height: 180px;
    overflow-y: auto;
  }

  &-item {
    padding: 4px 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--active {
      background-color: #0f62fe !important;
    }

    span {
      padding: 0 8px;
    }
  }
}

.plaidAccountsModal {
  max-width: none !important;
  width: auto;
  max-height: 80%;
  min-width: 300px !important;
  overflow-x: hidden;

  .table-page-footer {
    justify-content: flex-start;
    div {
      width: auto;
      min-width: auto;
    }
  }
}
