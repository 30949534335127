@import '/src/theme/constants/colors.scss';

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 84%;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: block;
  }
  @media (max-width: 1024px) {
    width: 78%;
    margin: 0px 8px 0px 8px !important;
  }

  @media (max-width: 768px) {
    width: 71%;
    margin: 0px 8px 0px 8px !important;
  }

  @media (max-width: 428px) {
    width: 96%;
    margin: 0px 8px 0px 8px !important;
  }
}
