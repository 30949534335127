@import '/src/theme/constants/colors';

.default {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: 0.5px;
  font-style: normal;
  font-stretch: normal;

  font-family: 'ProximaNova-Regular', sans-serif;
}

.h1 {
  font-size: 96px;
  font-weight: 300;
  line-height: 1.17;
  letter-spacing: -1.5px;
  font-family: 'ProximaNova-Light', sans-serif;
}
.h2 {
  font-size: 60px;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: -0.5px;
  font-family: 'ProximaNova-Light', sans-serif;
}
.h3 {
  font-size: 48px;
  font-weight: normal;
  line-height: 1.17;
  letter-spacing: normal;
  font-family: 'ProximaNova-Regular', sans-serif;
}
.h4 {
  font-size: 34px;
  font-weight: normal;
  line-height: 1.06;
  letter-spacing: normal;
  font-family: 'ProximaNova-Regular', sans-serif;
}
.h5 {
  font-size: 24px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: 0.18px;
  font-family: 'ProximaNova-Regular', sans-serif;
}
.h6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.15px;
  font-family: 'ProximaNova-Medium', sans-serif;
}

.title {
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0.18px;
  margin-bottom: 10px;
  font-family: 'ProximaNova-Regular', sans-serif;
}

.subtitle {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.15px;
  font-family: 'ProximaNova-Regular', sans-serif;
}

.subtitle2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  letter-spacing: 0.1px;
  font-family: 'ProximaNova-Medium', sans-serif;
}

.body {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  font-family: 'ProximaNova-Regular', sans-serif;
}

.body2 {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  font-family: 'ProximaNova-Regular', sans-serif;
}

.button {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.14;
  letter-spacing: 1.25px;
  font-family: 'ProximaNova-Regular', sans-serif;

  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 1;
    letter-spacing: 1.05px;
  }
}

.caption {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  font-family: 'ProximaNova-Regular', sans-serif;
}

.dark {
  color: rgba($white, 0.87);
}

.light {
  color: rgba($black, 0.87);
}

.breadcrumb {
  font-size: 14px;
  font-family: 'ProximaNova-Regular', sans-serif;
  line-height: 20px;

  &.dark {
    color: rgba($white, 0.38);
  }

  &.light {
    color: rgba($black, 0.38);
  }
}
