.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 16px 24px 16px;

  @media (max-width: 500px) {
    flex-direction: column;
    margin: 0px 0px 16px 0px;
    padding: 0px 8px 0px 8px;
  }
}
