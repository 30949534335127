@import '/src/theme/constants/colors';

.notification {
  display: inline-block;
  position: relative;
  cursor: pointer;
  z-index: 99;
  user-select: none;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-family: 'ProximaNova-Regular', sans-serif;
    line-height: 1.33;
    letter-spacing: 0.3px;
    font-weight: 500;
    font-size: 12px;
    padding: 16px;
    color: rgba($white, 0.87);

    &-dark {
      color: rgba($white, 0.87);
    }

    &-light {
      color: rgba($black, 0.87);
    }

    &-clean {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &-button {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
  }

  &-icon {
    &-badge {
      width: 6px;
      height: 6px;
      border-radius: 3px;
      position: absolute;
      top: 0;
      right: 0;

      &-dark {
        background-color: map-get($dark, 'badge-color');
      }
      &-light {
        background-color: map-get($light, 'badge-color');
      }
    }
  }

  &-input {
    display: none;
  }

  &-list-wrapper {
    position: absolute;
    right: 6px;
    top: 40px;

    @media (max-width: 414px) {
      right: -5px;
    }
  }

  &-arrow {
    font-size: 42px;

    &-dark {
      color: map-get($dark, 'notification-arrow');
    }

    &-light {
      color: map-get($light, 'notification-arrow');
    }

    &-content {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: -18px;
      padding-top: -21px;
    }
  }

  &-list {
    max-height: 431px;
    border-radius: 12px;
    padding: 8px;

    overflow: hidden;

    &-dark {
      border: 0px solid map-get($dark, 'notification-list-border');
      // background-color: map-get($dark, 'notification-arrow');
      background-color: rgba($white, 0.12);
    }

    &-light {
      border: 0px solid map-get($light, 'notification-list-border');
      background-color: rgba($black, 0.12);
    }

    &-container {
      border-radius: 12px;

      &-dark {
        background-color: $black;
        /*         -webkit-box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.1);
        -moz-box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.1);
        box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.1); */
      }

      &-light {
        background-color: $white;
        -webkit-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.notification-items {
  &-loading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 11px 0px;
    width: 370px;

    @media (max-width: 414px) {
      width: calc(100vw - 24px);
    }

    &-dark {
      > span {
        color: $white;
      }
    }

    &-light {
      > span {
        color: $white;
      }
    }

    > span {
      font-size: 14px;
      font-family: 'ProximaNova-Regular', sans-serif;
    }
  }
  &-empty-state {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 11px 0px;

    width: 370px;

    @media (max-width: 414px) {
      width: calc(100vw - 24px);
    }

    > span {
      font-size: 14px;
      font-family: 'ProximaNova-Regular', sans-serif;
    }

    &-dark {
      > span {
        color: $white;
      }
    }

    &-light {
      > span {
        color: $white;
      }
    }
  }
}

.list-content {
  overflow-y: scroll;
  max-height: 368px;
}
