@font-face {
  font-family: 'icomoon';
  src: url('assets/icons/icomoon.eot');
  src: url('assets/icons/icomoon.eot#iefix') format('embedded-opentype'),
    url('assets/icons/icomoon.ttf') format('truetype'), url('assets/icons/icomoon.woff') format('woff'),
    url('assets/icons/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* stylelint-disable */
  font-family: 'icomoon' !important;
  /* stylelint-enable */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-icon-navigation-receipt-long-black-24-dp:before {
  content: '\e92d';
}
.icon-icon-navigation-i-24-px:before {
  content: '\e92b';
  color: #f44336;
}
.icon-icon-navigation-d-24-px:before {
  content: '\e92c';
  color: #13cd3c;
}
.icon-icon-action-bar-chart-24:before {
  content: '\e928';
}
.icon-icon-action-varl-klar-m:before {
  content: '\e929';
}
.icon-icon-places-money-transfer-24-px-v-2:before {
  content: '\e92a';
}
.icon-icon-social-notifications-24-px1:before {
  content: '\e927';
}
.icon-icon-action-wb-sunny-24-px:before {
  content: '\e900';
}
.icon-icon-action-dark-mode-24-px:before {
  content: '\e901';
}
.icon-icon-action-dashboard-24-px:before {
  content: '\e902';
}
.icon-icon-action-password-24-px:before {
  content: '\e903';
}
.icon-icon-action-logout-24-px:before {
  content: '\e904';
}
.icon-icon-places-account-balance-24-px:before {
  content: '\e905';
}
.icon-icon-action-setting-24-px:before {
  content: '\e906';
}
.icon-icon-action-date:before {
  content: '\e907';
}
.icon-icon-navigation-double-arrow-24-px:before {
  content: '\e908';
}
.icon-icon-navigation-fullscreen-24-px:before {
  content: '\e909';
}
.icon-icon-action-favorite-border-24-px:before {
  content: '\e90a';
}
.icon-icon-navigation-fullscreen-exit-24-px:before {
  content: '\e90b';
}
.icon-icon-navigation-close-24-px:before {
  content: '\e90c';
}
.icon-icon-places-account-balance-wallet-24-px:before {
  content: '\e90d';
}
.icon-icon-social-notifications-24-px:before {
  content: '\e90e';
}
.icon-icon-navigation-more-horiz-24-px:before {
  content: '\e90f';
}
.icon-icon-navigation-more-vert-24-px:before {
  content: '\e910';
}
.icon-icon-navigation-apps-24-px:before {
  content: '\e911';
}
.icon-icon-action-search:before {
  content: '\e912';
}
.icon-icon-navigation-close-24-px-1:before {
  content: '\e913';
}
.icon-icon-navigation-expand-less-24-px:before {
  content: '\e914';
}
.icon-icon-navigation-arrow-drop-down-circle-24-px:before {
  content: '\e915';
}
.icon-icon-action-visibility:before {
  content: '\e916';
}
.icon-icon-navigation-expand-more-24-px:before {
  content: '\e917';
}
.icon-icon-action-favorite-24-px:before {
  content: '\e918';
}
.icon-icon-navigation-arrow-right-24-px:before {
  content: '\e919';
}
.icon-icon-navigation-chevron-left-24-px:before {
  content: '\e91a';
}
.icon-icon-navigation-chevron-right-24-px:before {
  content: '\e91b';
}
.icon-icon-navigation-menu-24-px:before {
  content: '\e91c';
}
.icon-icon-navigation-arrow-drop-down-24-px:before {
  content: '\e91d';
}
.icon-icon-navigation-arrow-drop-up-24-px:before {
  content: '\e91e';
}
.icon-icon-navigation-arrow-left-24-px:before {
  content: '\e91f';
}
.icon-icon-navigation-unfold-more-24-px:before {
  content: '\e920';
}
.icon-icon-navigation-file-download-24-px:before {
  content: '\e921';
}
.icon-icon-navigation-switch-right-24-px:before {
  content: '\e922';
}
.icon-icon-content-add-24-px:before {
  content: '\e923';
}
.icon-icon-navigation-east-24-px:before {
  content: '\e924';
}
.icon-icon-navigation-arrow-forward-ios-24-px:before {
  content: '\e925';
}
.icon-icon-navigation-arrow-back-ios-new-24-px:before {
  content: '\e926';
}
.icon-icon-daily-summary:before {
  content: '\e92e';
}
.icon-icon-pie-chart:before {
  content: '\e99a';
}
.icon-icon-stats-dots:before {
  content: '\e99b';
}
.icon-file-excel:before {
  content: '\eae2';
}
.icon-icon-info-24-px:before {
  content: '\ea0c';
}
