@import '/src/theme/constants/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.breadcrumbs-list {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0.18px;
  margin-bottom: 10px;
  font-family: 'ProximaNova-Regular', sans-serif;

  &-dark {
    color: rgba($white, 0.87);
  }

  &-light {
    color: rgba($black, 0.87);
  }
}

.breadcrumb {
  font-size: 14px;
  font-family: 'ProximaNova-Regular', sans-serif;
  line-height: 20px;
  margin-left: 4px;

  &:first-child {
    margin-left: 0px;
  }

  &-dark {
    color: rgba($white, 0.38);
  }

  &-light {
    color: rgba($black, 0.38);
  }
}
