@import '/src/theme/constants/colors';

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.currency {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 500px) {
    max-width: 45%;
  }

  &-symbol {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 5px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 16px;

    &-smallFont {
      font-size: 12px;
    }
  }

  &-name {
    flex: 1;
    font-family: 'ProximaNova-Medium', sans-serif;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-left: 8px;
    min-width: 145px;

    @media (max-width: 1024px) {
      line-height: 20px;
      font-size: 16px;
      min-width: 120px;
    }

    @media (max-width: 500px) {
      min-width: 100px;
      font-size: 16px;
    }
  }
}

.total {
  flex: 1;
  font-family: 'ProximaNova-Medium', sans-serif;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.15px;
  text-align: end;

  @media (max-width: 1024px) {
    line-height: 20px;
    font-size: 16px;
  }

  @media (max-width: 500px) {
    font-size: 16px;
    padding: 0px 8px;
  }

  &-dark {
    color: rgba(white, 0.87);
  }

  &-light {
    color: rgba(#1e1e1e, 0.87);
  }
}

.account-count {
  flex: 1;
  font-family: 'ProximaNova-Medium', sans-serif;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.15px;
  text-align: center;

  @media (max-width: 1024px) {
    line-height: 20px;
    font-size: 16px;
  }

  @media (max-width: 500px) {
    font-size: 16px;
  }

  &-dark {
    color: rgba(white, 0.6);
  }

  &-light {
    color: rgba(#1e1e1e, 0.6);
  }
}
