@import '/src/theme/constants/colors.scss';

.dailyActivities-select {
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 12px;
  padding: 4px 12px;
  margin: 4px 0;

  &-container {
    margin-top: 4px;
  }

  &-item {
    padding: 4px 2px;

    &--active {
      background-color: #0f62fe !important;
    }

    span {
      padding: 0 8px;
    }
  }
}

.select-item {
  padding: 4px 0px 4px 8px;
}

.select-option {
  padding: 0;
}

.select-icon-wrapper {
  margin-left: 42px !important;
}

.transparent {
  > div {
    padding-top: 0px !important;
  }
}

.table-wrapper {
  overflow-x: auto;
  margin-top: 32px;
  transform: rotateX(180deg);

  &::-webkit-scrollbar {
    display: block;
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: #0f62fe;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(223, 223, 223, 0.34);
    border-radius: 12px;

    &::-webkit-scrollbar-thumb:hover {
      background: #5490ff;
    }
  }
}

.table {
  display: block;
  width: 100%;
  margin-top: 32px;
  border-radius: 12px;
  border-collapse: collapse;
  transform: rotateX(180deg);

  &-dark {
    th,
    td {
      border: 1px solid #282828;
    }
  }
  &-light {
    th,
    td {
      border: 1px solid #e1e1e1;
    }
  }

  &-header {
    width: 100%;
    &-dark {
      background-color: rgba(255, 255, 255, 0.12);

      th,
      td {
        border: 1px solid #1f1f1f;
      }
    }
    &-light {
      background-color: rgba(30, 30, 30, 0.06);
    }
  }

  &-cell {
    &-dark {
      color: rgba(255, 255, 255, 0.87);
    }
    &-light {
      color: rgba(30, 30, 30, 0.87);
    }

    padding: 2px 30px;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.15px;

    &-noData {
      padding: 20px;
      text-align: center;
    }

    &-text {
      padding: 2px 0;
      font-size: 14px;
    }

    &-hasBalance {
      cursor: pointer;
      text-decoration: underline;
    }

    &-rightAlign {
      text-align: right;
    }
  }

  &-body {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    &-dark {
      color: rgba(255, 255, 255, 0.87);
    }
    &-light {
      color: rgba(30, 30, 30, 0.66);
    }

    td {
      white-space: nowrap;
      padding: 12px 16px;
    }
  }

  &-footer {
    display: block;

    &-row {
      display: block;

      td {
        border: none;
      }
    }
  }
}

.activitiesModal {
  max-width: none !important;
  width: 90%;
  max-height: 80%;
  min-width: 300px !important;
  overflow-x: hidden;

  .modalFilterArea {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .checkbox {
      display: flex;
      align-items: center;
    }
  }

  .downloadArea {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .card {
      padding: 0px;
      padding-top: 16px;
      width: 100%;
      overflow: hidden;

      &-item {
        overflow-y: scroll;
        overflow-x: hidden;
      }

      &-container {
        display: flex;
        justify-content: center;
      }

      &-right-item {
        font-family: 'ProximaNova-Regular', sans-serif;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        margin-right: 12px;

        cursor: pointer;

        > div {
          margin-left: 4px;
        }

        &-dark {
          color: #fff;

          &:hover {
            border-bottom: 1px solid #fff;
          }
        }
        &-light {
          color: rgb(68, 68, 68);

          &:hover {
            border-bottom: 1px solid rgb(68, 68, 68);
          }
        }
      }

      &-content {
        @media (max-width: 500px) {
          width: fit-content;
        }
      }
      &-title {
        padding-left: 12px;
      }
      &-subContent {
        font-family: 'ProximaNova-Regular', sans-serif;
        margin-top: 22px;
        padding-left: 12px;
        padding-right: 39px;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        cursor: pointer;
        user-select: none;

        &-dark {
          color: #fff;
        }
        &-light {
          color: rgb(68, 68, 68);
        }
        &-filter {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.5px;
          display: flex;
          align-items: center;
        }

        &-buttons {
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: 0.5px;
          display: flex;
          /*       div:first-child {
            border-right: 1px solid;
            margin-right: 10px;
            padding-right: 10px;
          } */
        }
      }

      &-download-loader {
        margin-right: 80px;
        margin-top: 10px;
      }
    }
  }

  @media (max-width: 400px) {
    width: 90%;
  }
}
