@import '/src/theme/constants/colors.scss';

.wrapper {
  height: 100px;
}

.content {
  padding-top: 10%;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
}

.buttonWrapped {
  display: flex;
  justify-content: center;
}

.card {
  padding: 30px 50px !important;
}

.cardWrapper {
  max-width: 40% !important;
}

.backButton {
  padding: 21px 27px !important;
  background-color: $red2 !important;
  color: rgba(255, 255, 255, 0.6);

  &:hover {
    background-color: $red !important;
    color: rgba(255, 255, 255, 1);
  }

  &-text {
    &-dark {
      color: white;
    }

    &-light {
      color: white;
    }
  }
}


.submitButton {
  padding: 21px 27px !important;
  background-color: #0f62fe;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    background-color: rgba(15, 98, 254, 0.8);


  }

  &-text {
    &-dark {
      color: white;
    }

    &-light {
      color: white;
    }
  }
}


.submittedMessage {
  padding: 21px 27px !important;
  background-color: #0f62fe;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;

  &-text {
    color: white;
  }
}

.cardTitle {
  font-size: 34px;
  margin-bottom: 16px;
  text-align: center;
}

.cardSubtitle {
  font-size: 14px;
  margin-bottom: 34px;
  text-align: center;
}

.error {
  color: $red2;
  text-align: center;
  margin: 16px 0px;
}

.rightItems {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;

  > div {
    margin-right: 8px;
  }
}

.logo {
  margin: 0 auto;
  padding: 16px 0px;

  > img {
    height: 64px;
  }
}
