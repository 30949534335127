@import '/src/theme/constants/colors';

.table {
  width: 100%;
  /*   margin-left: -16px;
  margin-right: -16px;
  padding: 16px 16px; */
  overflow: hidden;
  overflow-x: scroll;

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: scroll;
    /*     margin-left: -16px;
    margin-right: -16px;
 */
    &-item {
      min-width: 30%;
    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    user-select: none;

    border-bottom: 1px solid #1e1e1e;

    &-dark {
      background-color: rgba(255, 255, 255, 0.12);
    }
    &-light {
      background-color: rgba(30, 30, 30, 0.06);
    }

    &-item {
      font-family: 'ProximaNova-Medium', sans-serif;
      text-align: center;
      padding: 16px 0px;
      font-size: 16px;
      min-height: -webkit-fill-available;

      @media (max-width: 500px) {
        min-width: 35%;
      }

      &-dark {
        background-color: rgba(#ffffff, 0.12);
        color: rgba(white, 0.87);
      }

      &-light {
        background-color: rgba(#1e1e1e, 0.06);
        color: rgba(#1e1e1e, 0.87);
      }
    }

    &-single-item {
      font-family: 'ProximaNova-Medium', sans-serif;
      padding: 16px 0px;
      min-height: -webkit-fill-available;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      height: 56px;

      @media (max-width: 500px) {
        min-width: 35%;
      }

      &-dark {
        background-color: rgba(#ffffff, 0.12);
        color: rgba(white, 0.87);
      }

      &-light {
        background-color: rgba(#1e1e1e, 0.06);
        color: rgba(#1e1e1e, 0.87);
      }

      &:nth-child(5) {
        justify-content: end;
      }

      &-balance {
        justify-content: end;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      border-bottom: 1px solid #1e1e1e;

      &-dark {
        border-bottom: 1px solid rgba(#1e1e1e, 1);
      }

      &-light {
        border-bottom: 1px solid rgba(#1e1e1e, 0.12);
      }

      &:last-child {
        border-bottom: 0px;
      }

      &-noData {
        :last-child {
          color: rgb(167, 17, 17);
        }
      }

      &-item {
        font-family: 'ProximaNova-Regular', sans-serif;
        width: 20%;
        padding: 12px 0px;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.5px;

        &-dark {
          color: rgba(white, 0.87);
        }

        &-light {
          color: rgba(#1e1e1e, 0.87);
        }
      }
    }
  }
}
