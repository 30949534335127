@import '/src/theme/constants/colors';

.group-filter-card {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9999;

  &-dark {
    color: rgba(#fff, 0.87);
    background-color: map-get($dark, 'dropdown-option-background');
    box-shadow: 0px 10px 27px 8px rgba(0, 0, 0, 0.83);
  }

  &-light {
    color: rgba(#1a1a1a, 0.87);
    background-color: map-get($light, 'dropdown-option-background');
    box-shadow: 0px 10px 27px 8px rgba(255, 255, 255, 0.83);
  }

  border-bottom: 1px solid rgba(30, 30, 30, 0.12);
  height: 115px;
}