@import '/src/theme/constants/colors';

.card {
  &-balance {
    font-family: 'ProximaNova-Medium', sans-serif;
    color: rgba($white, 0.87);
    font-size: 48px;
    line-height: 1.17;
    justify-self: center;
    align-self: center;
    margin-bottom: 16px;

    @media (max-width: 768px) {
      font-size: 32px;
    }

    span {
      font-family: 'ProximaNova-Regular', sans-serif;
      font-size: 34px;
      line-height: 1.06;
      color: #42a5f5;
      margin-left: 5px;
    }

    &-dark {
      color: rgba($white, 0.87);
    }

    &-light {
      color: rgba($black, 0.87);
    }

    &-exchange {
      display: flex;
      font-family: 'ProximaNova-Regular', sans-serif;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.5px;
      color: rgba($white, 0.6);
      justify-self: center;
      align-self: center;

      &-dark {
        color: rgba($white, 0.6);
      }

      &-light {
        color: rgba($black, 0.6);
      }
    }
  }

  &-balance-2 {
    font-family: 'ProximaNova-Light', sans-serif;
    color: rgba($white, 0.56);
    font-size: 20px;
    line-height: 1.17;
    justify-self: center;
    align-self: center;
    margin: 18px 0px;

    span {
      font-family: 'ProximaNova-Light', sans-serif;
      font-size: 18px;
      line-height: 1.06;
      color: #42a5f5;
      margin-left: 5px;
    }

    &-dark {
      color: rgba($white, 0.56);
    }

    &-light {
      color: rgba($black, 0.56);
    }

    &-exchange {
      display: flex;
      font-family: 'ProximaNova-Regular', sans-serif;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.5px;
      color: rgba($white, 0.6);
      justify-self: center;
      align-self: center;

      &-dark {
        color: rgba($white, 0.6);
      }

      &-light {
        color: rgba($black, 0.6);
      }
    }
  }

  &-select-currency {
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: 12px;
    padding: 4px 12px;

    &-light {
      background-color: rgba(30, 30, 30, 0.06);
    }
  }
}

.select {
  padding: 0px;

  >span {
    left: 12px !important;
  }
}

.dropdown {
  min-width: 90px;
  /* height: 40px;
  margin-bottom: 40px; */
  padding: 0px !important;
}

.icon {
  color: red;
}

.card-content {
  justify-content: center;
  align-items: center;
}

.bank-name {
  font-family: 'ProximaNova-Regular', sans-serif;
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
  text-align: center;

  &-dark {
    color: rgba($white, 0.6);
  }

  &-light {
    color: rgba($black, 0.6);
  }
}