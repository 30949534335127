@import '/src/theme/constants/colors.scss';

.user {
  font-family: 'ProximaNova-Regular', sans-serif;

  &-light {
    color: #1a1a1a;
  }

  &-dark {
    color: #fff;
  }
}
.header-default {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 428px) {
    display: none;
    visibility: hidden;
  }
}
.defCompanyLink {
  text-decoration: none;
}
#header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 0px 0px 12px 12px;
  padding: 30px 20px;
  max-height: 75px;
  box-sizing: border-box;

  @media (max-width: 428px) {
    padding: 30px 10px !important;
  }

  &-left {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
  }

  &-right {
    display: flex;
    flex-grow: 11;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: 24px;

    @media (max-width: 428px) {
      padding-right: 20px;
    }

    > div {
      margin: 0px 8px;
    }
  }
}

.theme-dark {
  background-color: map-get($dark, 'header-background');
}

.theme-light {
  background-color: map-get($light, 'header-background');
}

.mobile-header {
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: none;

  @media (max-width: 428px) {
    display: flex;
  }
}

.mobile-menu-button {
  display: flex;
  width: 32px;
  height: 32px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  &:active {
    background-color: rgba(#fff, 0.12);
  }
}

.menu {
  background-color: #1a1a1a;
  width: 220px;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 99;
  overflow-y: scroll;

  &-light {
    background-color: map-get($light, 'sidebar-background');
  }

  &-dark {
    background-color: map-get($dark, 'sidebar-background');
  }

  &-header {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 75px;
  }

  &-content {
    background-color: transparent;
  }

  &-active {
    left: 0;
    transition: 350ms;
  }

  &-close {
    margin-right: 16px;
  }
}
