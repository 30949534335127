@import '/src/theme/constants/colors';

.option-item {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &:first-child {
    border-radius: 12px 12px 0px 0px;
  }

  &:last-child {
    border-radius: 0px 0px 12px 12px;
  }

  &-dark {
    background-color: map-get($dark, 'dropdown-option-selected-background');
  }

  &-light {
    background-color: map-get($light, 'dropdown-option-selected-background');
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: 'ProximaNova-Medium', sans-serif;
    padding: 16px 16px;
    font-size: 16px;
    line-height: 16px;

    &-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-left: 8px;
    }

    &-logo {
      height: 20px;
      width: 30px;
      margin: 0px 8px;
    }

    &-dark {
      color: rgba(#fff, 0.87);
      background-color: rgba(#1a1a1a, 0.56);
    }

    &-light {
      color: rgba(#1a1a1a, 0.87);
      background-color: rgba(#1a1a1a, 0.12);
    }
  }

  &-sub {
    display: flex;
    flex-direction: column;

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 16px 8px 8px;

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px;
      }

      &:last-child {
        border-bottom: 0px;
      }

      &-selected {
        > div {
          display: flex;
          flex: 1;
          padding: 8px 16px;
          background-color: map-get($light, 'dropdown-option-selected') !important;
          color: white;
          border-radius: 4px;
        }
      }

      &-dark {
        border-bottom: 1px solid map-get($dark, 'dropdown-option-border');
        // background-color: map-get($dark, 'dropdown-option-selected-background');
        color: map-get($dark, 'dropdown-option-color');

        &:hover {
          background-color: map-get($dark, 'dropdown-option-selected-hover');
        }
      }

      &-light {
        border-bottom: 1px solid map-get($light, 'dropdown-option-border');
        background-color: map-get($light, 'dropdown-option-selected-background');
        color: map-get($light, 'dropdown-option-color');

        &:hover {
          background-color: map-get($light, 'dropdown-option-selected-hover');
        }
      }
    }
  }
}

.currency {
  &-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    padding: 10px 40px 10px 15px;

    border-radius: 8px;

    font-family: 'ProximaNova-Medium', sans-serif;

    margin-right: 16px;

    &-dark {
      background-color: map-get($dark, 'currency-box-background');
      color: hexToRgba($white, 0.87);
    }

    &-light {
      background-color: map-get($light, 'currency-box-background');
      color: hexToRgba($black, 0.87);
    }
  }

  &-symbol {
    font-size: 18px;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    text-align: center;
    font-family: 'ProximaNova-Medium', sans-serif;
  }
}

.filters-select-bank-accounts-container {
  max-height: 400px;
  overflow-y: auto;

  & > div {
    margin: 0;
  }
}

.filters-select-bank-accounts {
  border-radius: 12px;
  height: 100%;
  width: auto;
  justify-content: space-between;
  padding-left: 16px;

  &-dark {
    background-color: rgba(255, 255, 255, 0.12);
  }

  &-light {
    background-color: rgba(30, 30, 30, 0.06);
  }

  @media (max-width: 1024px) {
    min-height: 46px;
  }
}
