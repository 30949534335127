@import '/src/theme/constants/colors';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0px 00px 20px;
  border-radius: 12px;
  margin-right: 16px;
  max-width: 200px;
  min-width: 200px;
  width: 200px;
  height: 600px;
  box-sizing: border-box;

  @media (max-width: 428px) {
    max-width: 100%;
    width: 100%;
    margin-bottom: 16px;
  }

  &-light {
    background-color: map-get($light, 'sidebar-background');
  }

  &-dark {
    background-color: map-get($dark, 'sidebar-background');
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  position: relative;

  @media (max-width: 428px) {
    &-item {
      display: flex;
      flex-direction: row;
      margin: 0px 0px 20px 0px;
      cursor: pointer;
      // justify-content: center;
    }
  }

  &-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 22px;
    text-decoration: none;
    cursor: pointer;

    &-text {
      margin-left: 10px;
      align-items: flex-start;
      font-size: 16px !important;
      letter-spacing: 0.15px;
      line-height: 1.5;
    }

    &:active {
      color: unset;
    }

    &-dark {
      &-icon {
        font-size: 24px !important;
        color: rgba($white, 0.31) !important;
      }

      .sidebar-item-text {
        color: rgba($white, 0.31) !important;

        &:hover {
          color: rgba($white, 0.78) !important;
        }
      }

      &--active {
        .sidebar-item-text { /* stylelint-disable-line */
          color: rgba($white, 0.78) !important;
        }

        .sidebar-item-dark-icon {
          color: rgba($white, 0.78) !important;
        }
      }
    }

    &-light {
      &-icon {
        font-size: 24px !important;
        color: rgba($black, 0.45) !important;
      }

      .sidebar-item-text { /* stylelint-disable-line */
        color: rgba($black, 0.45) !important;

        &:hover {
          color: rgba($black, 0.9) !important;
        }
      }

      &--active {
        .sidebar-item-text { /* stylelint-disable-line */
          color: rgba($black, 0.9) !important;
        }

        .sidebar-item-light-icon {
          color: rgba($black, 0.9) !important;
        }
      }
    }
  }

  &-externalLink {
    &-dark {
      color: rgba($white, 0.31) !important;

      &:hover {
        color: rgba($white, 0.78) !important;
      }

      &-icon {
        font-size: 24px !important;
        color: rgba($white, 0.31) !important;
      }
    }

    &-light {
      color: rgba($black, 0.45) !important;

      &:hover {
        color: rgba($black, 0.9) !important;
      }

      &-icon {
        font-size: 24px !important;
        color: rgba($black, 0.45) !important;
      }
    }

    &-text {
      margin-left: 10px;
      align-items: flex-start;
      font-size: 16px !important;
      letter-spacing: 0.15px;
      line-height: 1.5;
    }
  }

  &-modal-company-row {
    font-size: 16px;
    padding: 8px;
    cursor: pointer;

    &-dark {
      border-bottom: 1px solid #3c3c3c;

      &:hover {
        background-color: #464646;
      }
    }

    &-light {
      border-bottom: 1px solid #e5e5e5;

      &:hover {
        background-color: #d7d6d6;
      }
    }
  }
}
