@import '/src/theme/constants/colors';

.accordion {
  display: flex;
  flex-direction: column;

  &-content {
    flex: 1;
  }

  &-row {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid #1e1e1e;
    padding: 16px 0px 4px 0px;

    &-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      width: 100%;
    }

    &-item {
      display: flex;
    }

    &-arrow {
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: $white;
    }
  }

  &-expanted-content {
    display: none;

    &-opened {
      display: block;
    }
  }
}
.accordion:first-child .accordion-row {
  padding: 0px 0px 4px 0px;
}

.accordion:last-child .accordion-row {
  padding: 16px 0px 4px 0px;
  margin-bottom: 8px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .accordion .accordion-row {
    padding: 30px 0px !important;
  }

  .accordion:last-child .accordion-row {
    border-bottom: none;
    margin-bottom: 8px !important;
  }
}
