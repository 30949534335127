@import '/src/theme/constants/colors';

.notification {
  &-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    font-family: 'ProximaNova-Regular', sans-serif;

    width: 370px;

    @media (max-width: 414px) {
      width: calc(100vw - 24px);
    }

    &-onclick {
      cursor: pointer;
    }

    &-active {
      border-radius: 4px;

      &-dark {
        background-color: rgba($black, 0.65);
      }

      &-light {
        background-color: rgba($black, 0.12);
      }

      * {
        border-bottom: 0px solid rgba($white, 0.14) !important;
      }
    }

    &-text-area {
      letter-spacing: 0.15px;
      padding: 15px 0px;

      &-dark {
        color: rgba($white, 0.87);
        border-bottom: 1px solid rgba($white, 0.14);
      }

      &-light {
        color: rgba($black, 0.87);
        border-bottom: 1px solid rgba($black, 0.14);
      }

      &-title {
        font-size: 16px 0px;
        line-height: 1.5;
      }

      &-subtitle {
        font-size: 14px;
        line-height: 1.3;
      }
    }

    &-icon {
      padding: 16px;

      &-active {
        i {
          color: #42a5f5 !important;
        }
      }

      &-dark {
        i {
          font-size: 24px;
          color: rgba($white, 0.87);
        }
      }

      &-light {
        i {
          font-size: 24px;
          color: rgba($black, 0.87);
        }
      }
    }

    &:last-child {
      .notification-item-text-area {
        border-bottom: 0px !important;
      }
    }
  }
}
