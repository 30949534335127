@import '/src/theme/constants/colors';

.wrapper {
  font-family: 'ProximaNova-Regular', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  padding: 8px 0px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    min-height: var('--minHeight');
  }
  &-total-balance {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    align-self: flex-end;
    width: 100%;

    &-ratio {
      display: flex;
      font-family: 'ProximaNova-Regular', sans-serif;
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: 0.25px;
      color: #f44336;
    }

    &-total {
      display: flex;
      margin: 5px 0 0px 0px;
      font-family: 'ProximaNova-Regular', sans-serif;
      font-size: 24px;
      line-height: 1;
      letter-spacing: 0.18px;
      //color: rgba(255, 255, 255, 0.87);
      &-dark {
        color: rgba(255, 255, 255, 0.87);
      }

      &-light {
        color: rgba(30, 30, 30, 0.87);
      }
    }
  }
}

.bank-list-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
