@import '/src/theme/constants/colors.scss';

.card {
  display: flex;
  flex-direction: column;
  padding: 10px !important;
  width: 100%;
  margin-bottom: -34px;

  @media (max-width: 1024px) {
    margin-bottom: -12px;
  }

  &-selectItem {
    display: flex;
    justify-content: space-between;
  }
}

.lastItem {
  > div {
    padding: 6px 0 !important;
  }
}

.filter-input {
  display: flex;
  justify-content: space-between;

  &-item {
    margin-top: 16px;
    margin-bottom: 0;

    @media (max-width: 1024px) {
      flex: 1;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.second-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0;
  min-width: 230px;

  @media (max-width: 1024px) {
    margin-top: 16px;
  }
}

.basis-group {
  flex-basis: 30%;
}

.basis-group-banks {
  flex-basis: 22%;
}
