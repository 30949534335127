.select-language-container {
  background-color: #121212;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
}

.select-language-item {
  line-height: 10px;
  padding: 0 8px 5px 8px;
  // background-color: unset;
  // color: unset;

  span {
    padding: 1px;
    text-align: -webkit-center;

  }
}