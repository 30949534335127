@import '/src/theme/constants/colors';

.option-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
  height: 60px;
  padding: 8px;

  &:hover {
    background-color: rgba(77, 77, 77, 0.5) !important;
  }

  &-select-all {
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &-select-half {
    background-color: rgba(#0f62fe, 0.2) !important;

    &:hover {
      background-color: rgba(#0f62fe, 0.3) !important;
    }
  }

  &-dark {
    color: rgba(#fff, 0.87);
    background-color: map-get($dark, 'dropdown-option-background');
    border-bottom: 1px solid map-get($dark, 'dropdown-option-border');

    &:last-child {
      border-bottom: 0px solid map-get($dark, 'dropdown-option-border');
    }
  }

  &-light {
    color: rgba(#1a1a1a, 0.87);
    background-color: map-get($light, 'dropdown-option-background');
    border-bottom: 1px solid map-get($light, 'dropdown-option-border');
  }

  &-selected {
    background-color: #0f62fe;
    color: rgba(#fff, 0.87);
  }

  &-all {
    text-align: center;
  }

  &-name {
    flex: 1;
    border-radius: 4px;
    padding: 8px 4px 8px 4px;

    >span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /* number of lines to show */
      -webkit-box-orient: vertical;
    }

    &-selected {
      background-color: #0f62fe;
      color: rgba(#fff, 0.87);
    }

    &-hasArrow {
      margin-right: 40px;
    }
  }

  abbr[title] {
    flex: 1;
    text-decoration: none;
  }

  &-show-more-wrapper {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;

    width: 50px;

    &-visible {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-show-more {
    display: none;
    border-radius: 4px;
    background-color: rgba(rgb(77, 77, 77), 0.15);
    z-index: 99;
    width: 30px;
    height: 30px;

    &-selected {
      background-color: rgba(77, 77, 77, 0.75);

      >span {
        color: #fff !important;
      }
    }

    &-visible {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.option-sub-items {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-visible {
    display: block;
  }
}