@import '/src/theme/constants/colors';

.icon {
  font-size: 24px;
  &-dark {
    color: map-get($dark, 'icon');
  }

  &-light {
    color: map-get($light, 'icon');
  }
}
