.TRY {
  color: #fbc34e;
}
.TRY-bg {
  background-color: #fbc34e;
}

.EUR {
  color: #3d88c8;
}
.EUR-bg {
  background-color: #3d88c8;
}

.USD {
  color: #5ab466;
}
.USD-bg {
  background-color: #5ab466;
}

.GBP {
  color: #805ab4;
}
.GBP-bg {
  background-color: #805ab4;
}

.CNY {
  color: #ff7043;
}
.CNY-bg {
  background-color: #ff7043;
}

.CHF {
  color: #5ab3b4;
}
.CHF-bg {
  background-color: #5ab3b4;
}

.CAD {
  color: #d7909e;
}
.CAD-bg {
  background-color: #d7909e;
}

.RUB {
  color: #927f7a;
}
.RUB-bg {
  background-color: #927f7a;
}

.JPY {
  color: #c7a062;
}
.JPY-bg {
  background-color: #c7a062;
}

.YP {
  color: #d31d1d;
}
.YP-bg {
  background-color: #d31d1d;
}

.DKK {
  color: #91a388;
}
.DKK-bg {
  background-color: #91a388;
}

.AED {
  color: #997679;
}
.AED-bg {
  background-color: #997679;
}

.KWD {
  color: #997679;
}
.KWD-bg {
  background-color: #997679;
}

.SEK {
  color: #81806c;
}
.SEK-bg {
  background-color: #81806c;
}

.RON {
  color: #637f80;
}

.RON-bg {
  background-color: #637f80;
}

.AUD {
  color: #5ea379;
}

.AUD-bg {
  background-color: #5ea379;
}

.NOK {
  color: #556fc4;
}

.NOK-bg {
  background-color: #556fc4;
}

.XAU {
  color: #d4ae31;
}

.XAU-bg {
  background-color: #d4ae31;
}

.SAR {
  color: #b457b8;
}

.SAR-bg {
  background-color: #b457b8;
}

.KZT {
  color: #618d73;
}

.KZT-bg {
  background-color: #618d73;
}

.PLN {
  color: #a6b66c;
}

.PLN-bg {
  background-color: #a6b66c;
}

.ZAR {
  color: #314892;
}

.ZAR-bg {
  background-color: #314892;
}

.ARS {
  color: #245737;
}

.ARS-bg {
  background-color: #245737;
}
