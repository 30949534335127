@import '/src/theme/constants/colors.scss';

.content {
  display: flex;
  flex: 1;
  flex-direction: row;

  margin: 0px 16px 16px 16px;

  box-sizing: border-box;

  @media (max-width: 500px) {
    flex-direction: column;
    margin: 0px 0px 16px 0px;
  }
}
