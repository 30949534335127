@import '/src/theme/constants/colors';

.filters-select-currency-container {
  max-height: 400px;
  overflow-y: auto;
}

.filters-select-currency {
  border-radius: 12px;
  height: 100%;
  width: auto;
  justify-content: space-between;
  padding-left: 16px;

  &-dark {
    background-color: rgba(255, 255, 255, 0.12);
  }

  &-light {
    background-color: rgba(30, 30, 30, 0.06);
  }

  @media (max-width: 1024px) {
    min-height: 46px;
  }
}

.filters-select-item {
  padding: 12px;

  &-active {
    span {
      display: block;
      line-height: unset;
    }
  }
}
