@import '/src/theme/constants/colors';

.card {
  min-height: 0px !important;
  margin-top: 32px;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 500px) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.bank-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bank-name {
  font-family: 'ProximaNova-Medium', sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.15px;

  @media (max-width: 768px) {
    max-width: 40%;
    font-size: 16px;
  }

  @media (max-width: 500px) {
    max-width: 100%;
    font-size: 14px;
    margin-bottom: 8px;
  }

  &-dark {
    color: rgba($white, 0.87);
  }

  &-light {
    color: rgba($black, 0.87);
  }
}

.currency-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    margin-right: 16px;
  }
}

.account-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  &-dark {
    > p {
      color: rgba($white, 0.87);
    }
  }

  &-light {
    > p {
      color: rgba($black, 0.87);
    }
  }

  > p {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 0px 0px 0px 8px;
  }
}

.tooltip {
  border-radius: 12px;
  padding: 2px 6px;
  cursor: pointer;
  margin-left: 3px;
  &-light {
    color: rgba(255, 255, 255, 0.87);
    background-color: rgb(0 0 0 / 18%);
  }
  &-dark {
    color: rgb(223 216 216 / 87%);
    background-color: rgb(193 193 193 / 35%);
  }
}

.lastDate {
  &-success {
    background-color: #1a951a;
    padding: 4px 5px;
    border-radius: 6px;
    &-dark {
      color: rgba(255, 255, 255, 0.87);
    }
    &-light {
      color: rgba(30, 30, 30, 0.87);
    }
  }
  &-error {
    background-color: #f44336;
    padding: 4px 5px;
    border-radius: 6px;
    &-dark {
      color: rgba(255, 255, 255, 0.87);
    }
    &-light {
      color: rgba(30, 30, 30, 0.87);
    }
  }
}
