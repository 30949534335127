@import '/src/theme/constants/colors';

.button {
  display: flex;
  flex-direction: column;
  width: 104px;
  height: 104px;
  border-radius: 12px;
  background-color: transparent;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 10px;
  padding: 0 2px;

  user-select: none;

  cursor: pointer;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    min-width: 104px;
  }
  &-dark {
    background-color: #1e1e1e;
  }

  &-light {
    background-color: rgba(#1a1a1a, 0.06);
  }

  &-img-error {
    justify-content: center;
    align-items: center;
  }
}

.img {
  width: 52px;
  height: 52px;
  filter: invert(0.5) !important;
  object-fit: contain;
  opacity: 0.25 !important;

  &-active {
    filter: invert(0) !important;
    opacity: 1 !important;
  }

  &-error {
    display: flex;
    width: 100%;
    height: 100%;
    align-self: center;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: 'ProximaNova-Regular', sans-serif;

    > div {
      width: 80%;
      word-wrap: break-word;
      white-space: normal;
      align-self: center;
      text-align: center;
    }
  }
}

.bankName {
  display: inline-block;
  max-width: 100%;
  font-family: 'ProximaNova-Regular', sans-serif;
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 2px;

  &-dark {
    color: #fff;
  }

  &-light {
    color: rgba(30, 30, 30, 0.6);
  }
}
