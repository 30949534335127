@import '/src/theme/constants/colors';

.widget {
  display: flex;
  width: 100%;
  flex-direction: column;
  min-width: 275px;
  margin: 0 auto;
  margin-bottom: 16px;

  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
    display: block;
  }

  &-title {
    font-family: 'ProximaNova-Medium', sans-serif;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin: 15px 0px 15px 0px;

    @media (max-width: 450px) {
      font-size: 16px;
    }

    &-dark {
      color: rgba($white, 0.87);
    }

    &-light {
      color: rgba($black, 0.87);
    }
  }
}

.children {
  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.row {
  @media (min-width: 990px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }


  [data-tag='card'] {
    flex: 1 1 100%;
    margin-bottom: 1.2%;
    max-height: 40%;
    min-height: 100%;

    @media (max-width: 767px) {
      display: block !important;
      flex: 1 1 100% !important;
      margin-bottom: 1.6% !important;
      max-width: 100% !important;
    }

    @media (max-width: 414px) {
      width: -webkit-fill-available;
      display: block !important;
    }
  }

  &-2 {
    [data-tag='card'] {
      flex: 1 1 49% !important;
      max-width: 49% !important;
      margin-right: 2% !important;
      margin-bottom: 2.85% !important;
      padding: 0px;

      >div {
        padding: 16px;
      }

      &:nth-child(even) {
        margin-right: 0px !important;
      }

      /*    @media (max-width: 1100px) { */
      @media (max-width: 767px) {
        flex: 1 1 100% !important;
        margin-right: 0px !important;
        margin-bottom: 4.6% !important;
        max-width: 100% !important;
      }
    }
  }

  &-3 {

    /* stylelint-disable */
    [data-tag='card'] {
      /* stylelint-enable */

      flex: 1 1 32% !important;
      max-width: 32% !important;
      margin-right: 1.98% !important;
      margin-bottom: 2.85% !important;
      padding: 0px;

      >div {
        padding: 16px;
      }

      &:nth-child(3n) {
        margin-right: 0px !important;
      }

      @media (max-width: 767px) {
        flex: 1 1 100% !important;
        margin-right: 0px !important;
        max-width: 100% !important;
      }
    }
  }

  &-4 {

    /* stylelint-disable */
    [data-tag='card'] {
      /* stylelint-enable */
      flex: 1 1 24.2% !important;
      max-width: 24.2% !important;
      margin-right: 1.05% !important;
      margin-bottom: 2.85% !important;
      padding: 0px;

      >div {
        padding: 16px;
      }

      &:nth-child(4n) {
        margin-right: 0px !important;
      }

      @media (max-width: 767px) {
        flex: 1 1 100% !important;
        margin-right: 0px !important;
        max-width: 100% !important;
      }
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.center {
  justify-content: center;
  align-items: center;
}

.between {
  justify-content: space-between;
  align-items: center;
}