/* stylelint-disable */
[data-theme='dark'] {
  /* .DateInput__small {
		 width: 118px !important;
	}
	 */
  font-family: 'ProximaNova-Regular', sans-serif;
}
[data-theme='dark'] .DateRangePicker {
  font-family: 'ProximaNova-Regular', sans-serif !important;
  position: absolute;
  left: -2px;
}
[data-theme='dark'] .DateRangePickerInput_arrow_1 {
  display: none !important;
}
[data-theme='dark'] .CalendarDay__selected_span,
[data-theme='dark'] .CalendarDay__selected_span:active,
[data-theme='dark'] .CalendarDay__selected_span:hover,
[data-theme='dark'] .CalendarDay__hovered_span,
[data-theme='dark'] .CalendarDay__hovered_span:hover {
  background: #263b62 !important;
  border: none;
  color: white !important;
}
[data-theme='dark'] .CalendarDay__selected,
[data-theme='dark'] .CalendarDay__selected:active {
  background: #0f62fe !important;
  border-radius: 100%;
  border: none;
  background-color: #0f62fe;
  color: white !important;
}
[data-theme='dark'] .DateInput_input__focused {
  border-bottom-color: #263b62 !important;
}
[data-theme='dark'] .CalendarDay__lastDayOfWeek {
  border-radius: 0px 20px 20px 0px;
}
[data-theme='dark'] .CalendarDay__firstDayOfWeek {
  border-radius: 20px 0px 0px 20px;
}
[data-theme='dark'] .DateRangePickerInput {
  background-color: transparent;
  border: none;
}
[data-theme='dark'] .CalendarMonth {
  padding: 10px;
}
[data-theme='dark'] .CalendarMonth_table > tr,
[data-theme='dark'] .CalendarMonth_table td {
  background-color: transparent;
  border: none;
  color: white;
  border-spacing: 0 1em;
}
[data-theme='dark'] .DateRangePicker_picker {
  left: 0px !important;
  top: 46px !important;
  border-radius: 12px;
  background-color: #121212;
}
[data-theme='dark'] .DateRangePicker_picker td {
  border-spacing: 2px;
  margin-bottom: 4px;
}
[data-theme='dark'] .DateRangePicker_picker > div {
  background-color: rgba(255, 255, 255, 0.12);
  border: none;
  color: white;
}
[data-theme='dark'] .DateRangePicker_picker .DayPickerNavigation_button {
  background-color: transparent;
  border: none;
  color: white;
}
[data-theme='dark'] .DateRangePicker_picker .CalendarMonth_caption {
  color: white;
}
[data-theme='dark'] .CalendarDay__selected_end {
  position: relative;
  border-radius: 100%;
}
[data-theme='dark'] .CalendarDay__selected_end::before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #263b62;
  width: 50%;
  left: 0;
  top: 0;
  z-index: -1;
}
[data-theme='dark'] .CalendarDay__selected_start {
  border-radius: 100%;
  position: relative;
}
[data-theme='dark'] .CalendarDay__selected_start::after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #263b62;
  width: 50%;
  right: 0;
  top: 0;
  z-index: -1;
}
[data-theme='dark'] .DateInput {
  display: none;
  height: 36px !important;
  background-color: transparent;
}
[data-theme='dark'] .DateInput > input {
  background-color: transparent;
  font-weight: 500;
}
[data-theme='dark'] .DayPicker_transitionContainer__horizontal,
[data-theme='dark'] .CalendarMonth,
[data-theme='dark'] .CalendarMonthGrid {
  background-color: transparent;
}
[data-theme='dark'] .DayPicker_transitionContainer {
  box-sizing: content-box;
}
[data-theme='dark'] .CalendarDay__blocked_out_of_range,
[data-theme='dark'] .CalendarDay__blocked_out_of_range:active,
[data-theme='dark'] .CalendarDay__blocked_out_of_range:hover {
  color: rgba(255, 255, 255, 0.12) !important;
}
[data-theme='dark'] .DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
[data-theme='dark'] .DayPickerNavigation_svg__vertical {
  width: 25px;
  height: 45px;
  fill: rgba(255, 255, 255, 0.87);
}
[data-theme='dark'] .DayPickerNavigation_button__verticalDefault {
  width: 30% !important;
  box-shadow: none;
}
[data-theme='dark'] .DayPicker__withBorder {
  border-radius: 12px;
}

[data-theme='light'] .DateRangePicker {
  font-family: 'ProximaNova-Regular', sans-serif !important;
  position: absolute;
  left: -27px;
}
[data-theme='light'] .DateRangePickerInput_arrow_1 {
  display: none !important;
}
[data-theme='light'] .CalendarDay__selected_span,
[data-theme='light'] .CalendarDay__selected_span:active,
[data-theme='light'] .CalendarDay__selected_span:hover,
[data-theme='light'] .CalendarDay__hovered_span,
[data-theme='light'] .CalendarDay__hovered_span:hover {
  background: rgba(15, 98, 254, 0.25) !important;
  border: none;
}
[data-theme='light'] .CalendarDay__selected,
[data-theme='light'] .CalendarDay__selected:active {
  background: #0f62fe !important;
  border-radius: 100%;
  border: none;
  background-color: #0f62fe;
  color: white !important;
}
[data-theme='light'] .DateInput_input__focused {
  border-bottom-color: rgba(15, 98, 254, 0.25) !important;
}
[data-theme='light'] .CalendarDay__lastDayOfWeek {
  border-radius: 0px 20px 20px 0px;
}
[data-theme='light'] .CalendarDay__firstDayOfWeek {
  border-radius: 20px 0px 0px 20px;
}
[data-theme='light'] .DateRangePickerInput {
  background-color: transparent;
  border: none;
}
[data-theme='light'] .CalendarMonth {
  padding: 10px;
}
[data-theme='light'] .CalendarMonth_table > tr,
[data-theme='light'] .CalendarMonth_table td {
  background-color: transparent;
  border: none;
  border-spacing: 0 1em;
}
[data-theme='light'] .DateRangePicker_picker {
  left: 0px !important;
  top: 46px !important;
  border-radius: 12px;
  background-color: #fff;
}
[data-theme='light'] .DateRangePicker_picker td {
  border-spacing: 2px;
  margin-bottom: 4px;
}
[data-theme='light'] .DateRangePicker_picker > div {
  background-color: rgba(255, 255, 255, 0.12);
  border: none;
  color: rgba(30, 30, 30, 1) !important;
}
[data-theme='light'] .DateRangePicker_picker .DayPickerNavigation_button {
  background-color: transparent;
  border: none;
  color: rgba(30, 30, 30, 1) !important;
}
[data-theme='light'] .DateRangePicker_picker .CalendarMonth_caption {
  color: rgba(30, 30, 30, 0.66) !important;
}
[data-theme='light'] .CalendarDay__selected_end {
  color: white !important;
  position: relative;
  border-radius: 100%;
}
[data-theme='light'] .CalendarDay__selected_end::before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: rgba(15, 98, 254, 0.25);
  width: 50%;
  left: 0;
  top: 0;
  z-index: -1;
}
[data-theme='light'] .CalendarDay__selected_start {
  border-radius: 100%;
  color: white !important;
  position: relative;
}
[data-theme='light'] .CalendarDay__selected_start::after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: rgba(15, 98, 254, 0.25);
  width: 50%;
  right: 0;
  top: 0;
  z-index: -1;
}
[data-theme='light'] .DateInput {
  display: none;
  height: 36px !important;
  background-color: transparent;
}
[data-theme='light'] .DateInput > input {
  background-color: transparent;
  font-weight: 500;
}
[data-theme='light'] .DayPicker_transitionContainer__horizontal,
[data-theme='light'] .CalendarMonth,
[data-theme='light'] .CalendarMonthGrid {
  background-color: transparent;
}
[data-theme='light'] .DayPicker_transitionContainer {
  box-sizing: content-box;
}
[data-theme='light'] .CalendarDay__blocked_out_of_range,
[data-theme='light'] .CalendarDay__blocked_out_of_range:active,
[data-theme='light'] .CalendarDay__blocked_out_of_range:hover {
  color: rgba(30, 30, 30, 0.2) !important;
}
[data-theme='light'] .DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
[data-theme='light'] .DayPickerNavigation_svg__vertical {
  width: 25px;
  height: 45px;
  fill: rgba(255, 255, 255, 0.87);
}
[data-theme='light'] .DayPickerNavigation_button__verticalDefault {
  width: 30% !important;
  box-shadow: none;
}
[data-theme='light'] .DayPicker__withBorder {
  border-radius: 12px;
}
[data-theme='light'] .CalendarDay__selected_span {
  color: rgba(30, 30, 30, 0.87) !important;
}

[data-theme='dark'] .SingleDatePicker {
  font-family: 'ProximaNova-Regular', sans-serif !important;
  position: absolute;
  left: -2px;
}
[data-theme='dark'] .SingleDatePickerInput_arrow_1 {
  display: none !important;
}
[data-theme='dark'] .CalendarDay__selected_span,
[data-theme='dark'] .CalendarDay__selected_span:active,
[data-theme='dark'] .CalendarDay__selected_span:hover,
[data-theme='dark'] .CalendarDay__hovered_span,
[data-theme='dark'] .CalendarDay__hovered_span:hover {
  background: #263b62 !important;
  border: none;
  color: white !important;
}
[data-theme='dark'] .CalendarDay__selected,
[data-theme='dark'] .CalendarDay__selected:active {
  background: #0f62fe !important;
  border-radius: 100%;
  border: none;
  background-color: #0f62fe;
  color: white !important;
}
[data-theme='dark'] .DateInput_input__focused {
  border-bottom-color: #263b62 !important;
}
[data-theme='dark'] .CalendarDay__lastDayOfWeek {
  border-radius: 0px 20px 20px 0px;
}
[data-theme='dark'] .CalendarDay__firstDayOfWeek {
  border-radius: 20px 0px 0px 20px;
}
[data-theme='dark'] .SingleDatePickerInput {
  background-color: transparent;
  border: none;
}
[data-theme='dark'] .CalendarMonth {
  padding: 10px;
}
[data-theme='dark'] .CalendarMonth_table > tr,
[data-theme='dark'] .CalendarMonth_table td {
  background-color: transparent;
  border: none;
  color: white;
  border-spacing: 0 1em;
}
[data-theme='dark'] .SingleDatePicker_picker {
  left: 0px !important;
  top: 46px !important;
  border-radius: 12px;
  background-color: #121212;
}
[data-theme='dark'] .SingleDatePicker_picker td {
  border-spacing: 2px;
  margin-bottom: 4px;
}
[data-theme='dark'] .SingleDatePicker_picker > div {
  background-color: rgba(255, 255, 255, 0.12);
  border: none;
  color: white;
}
[data-theme='dark'] .SingleDatePicker_picker .DayPickerNavigation_button {
  background-color: transparent;
  border: none;
  color: white;
}
[data-theme='dark'] .SingleDatePicker_picker .CalendarMonth_caption {
  color: white;
}
[data-theme='dark'] .CalendarDay__selected_end {
  position: relative;
  border-radius: 100%;
}
[data-theme='dark'] .CalendarDay__selected_end::before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #263b62;
  width: 50%;
  left: 0;
  top: 0;
  z-index: -1;
}
[data-theme='dark'] .CalendarDay__selected_start {
  border-radius: 100%;
  position: relative;
}
[data-theme='dark'] .CalendarDay__selected_start::after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #263b62;
  width: 50%;
  right: 0;
  top: 0;
  z-index: -1;
}
[data-theme='dark'] .DateInput {
  display: none;
  height: 36px !important;
  background-color: transparent;
}
[data-theme='dark'] .DateInput > input {
  background-color: transparent;
  font-weight: 500;
}
[data-theme='dark'] .DayPicker_transitionContainer__horizontal,
[data-theme='dark'] .CalendarMonth,
[data-theme='dark'] .CalendarMonthGrid {
  background-color: transparent;
}
[data-theme='dark'] .DayPicker_transitionContainer {
  box-sizing: content-box;
}
[data-theme='dark'] .CalendarDay__blocked_out_of_range,
[data-theme='dark'] .CalendarDay__blocked_out_of_range:active,
[data-theme='dark'] .CalendarDay__blocked_out_of_range:hover {
  color: rgba(255, 255, 255, 0.12) !important;
}
[data-theme='dark'] .DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
[data-theme='dark'] .DayPickerNavigation_svg__vertical {
  width: 25px;
  height: 45px;
  fill: rgba(255, 255, 255, 0.87);
}
[data-theme='dark'] .DayPickerNavigation_button__verticalDefault {
  width: 30% !important;
  box-shadow: none;
}
[data-theme='dark'] .DayPicker__withBorder {
  border-radius: 12px;
}

[data-theme='light'] .SingleDatePicker {
  font-family: 'ProximaNova-Regular', sans-serif !important;
  position: absolute;
  left: -27px;
}
[data-theme='light'] .SingleDatePickerInput_arrow_1 {
  display: none !important;
}
[data-theme='light'] .CalendarDay__selected_span,
[data-theme='light'] .CalendarDay__selected_span:active,
[data-theme='light'] .CalendarDay__selected_span:hover,
[data-theme='light'] .CalendarDay__hovered_span,
[data-theme='light'] .CalendarDay__hovered_span:hover {
  background: rgba(15, 98, 254, 0.25) !important;
  border: none;
}
[data-theme='light'] .CalendarDay__selected,
[data-theme='light'] .CalendarDay__selected:active {
  background: #0f62fe !important;
  border-radius: 100%;
  border: none;
  background-color: #0f62fe;
  color: white !important;
}
[data-theme='light'] .DateInput_input__focused {
  border-bottom-color: rgba(15, 98, 254, 0.25) !important;
}

[data-theme='light'] .SingleDatePickerInput {
  background-color: transparent;
  border: none;
}
[data-theme='light'] .CalendarMonth {
  padding: 10px;
}
[data-theme='light'] .CalendarMonth_table > tr,
[data-theme='light'] .CalendarMonth_table td {
  background-color: transparent;
  border: none;
  border-spacing: 0 1em;
}
[data-theme='light'] .SingleDatePicker_picker {
  left: 0px !important;
  top: 46px !important;
  border-radius: 12px;
  background-color: #fff;
}
[data-theme='light'] .SingleDatePicker_picker td {
  border-spacing: 2px;
  margin-bottom: 4px;
}
[data-theme='light'] .SingleDatePicker_picker > div {
  background-color: rgba(255, 255, 255, 0.12);
  border: none;
  color: rgba(30, 30, 30, 1) !important;
}
[data-theme='light'] .SingleDatePicker_picker .DayPickerNavigation_button {
  background-color: transparent;
  border: none;
  color: rgba(30, 30, 30, 1) !important;
}
[data-theme='light'] .SingleDatePicker_picker .CalendarMonth_caption {
  color: rgba(30, 30, 30, 0.66) !important;
}
[data-theme='light'] .CalendarDay__selected_end {
  color: white !important;
  position: relative;
  border-radius: 100%;
}
[data-theme='light'] .CalendarDay__selected_end::before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: rgba(15, 98, 254, 0.25);
  width: 50%;
  left: 0;
  top: 0;
  z-index: -1;
}
[data-theme='light'] .CalendarDay__selected_start {
  border-radius: 100%;
  color: white !important;
  position: relative;
}
[data-theme='light'] .CalendarDay__selected_start::after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: rgba(15, 98, 254, 0.25);
  width: 50%;
  right: 0;
  top: 0;
  z-index: -1;
}
[data-theme='light'] .DateInput {
  display: none;
  height: 36px !important;
  background-color: transparent;
}
[data-theme='light'] .DateInput > input {
  background-color: transparent;
  font-weight: 500;
}
[data-theme='light'] .DayPicker_transitionContainer__horizontal,
[data-theme='light'] .CalendarMonth,
[data-theme='light'] .CalendarMonthGrid {
  background-color: transparent;
}
[data-theme='light'] .DayPicker_transitionContainer {
  box-sizing: content-box;
}
[data-theme='light'] .CalendarDay__blocked_out_of_range,
[data-theme='light'] .CalendarDay__blocked_out_of_range:active,
[data-theme='light'] .CalendarDay__blocked_out_of_range:hover {
  color: rgba(30, 30, 30, 0.2) !important;
}
[data-theme='light'] .DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
[data-theme='light'] .DayPickerNavigation_svg__vertical {
  width: 25px;
  height: 45px;
  fill: rgba(255, 255, 255, 0.87);
}
[data-theme='light'] .DayPickerNavigation_button__verticalDefault {
  width: 30% !important;
  box-shadow: none;
}
[data-theme='light'] .DayPicker__withBorder {
  border-radius: 12px;
}
[data-theme='light'] .CalendarDay__selected_span {
  color: rgba(30, 30, 30, 0.87) !important;
}
