@import '/src/theme/constants/colors.scss';

.card {
  padding: 0px;
  padding-top: 16px;
  width: 100%;
  overflow: hidden;

  &-item {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &-container {
    display: flex;
    justify-content: center;
  }

  &-right-item {
    font-family: 'ProximaNova-Regular', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-right: 12px;

    .downloadIcon {
      font-size: 20px !important;
    }

    cursor: pointer;

    > div {
      margin-left: 4px;
    }

    &-dark {
      color: #fff;

      &:hover {
        border-bottom: 1px solid #fff;
      }
    }
    &-light {
      color: rgb(68, 68, 68);

      &:hover {
        border-bottom: 1px solid rgb(68, 68, 68);
      }
    }
  }

  &-content {
    @media (max-width: 500px) {
      width: fit-content;
    }
  }
  &-title {
    padding-left: 12px;
  }
  &-subContent {
    font-family: 'ProximaNova-Regular', sans-serif;
    margin-top: 22px;
    padding-left: 12px;
    padding-right: 39px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    cursor: pointer;
    user-select: none;

    &-dark {
      color: #fff;
    }
    &-light {
      color: rgb(68, 68, 68);
    }
    &-filter {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.5px;
      display: flex;
      align-items: center;
    }

    &-buttons {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.5px;
      display: flex;
      /*       div:first-child {
        border-right: 1px solid;
        margin-right: 10px;
        padding-right: 10px;
      } */
    }
  }

  &-download-loader {
    margin-right: 80px;
    margin-top: 10px;
  }
}

.filter-items {
  // margin-right: 10px;
  flex: 1 auto;
  margin-right: 10px;

  &:last-child {
    margin-right: 0px;
  }

  @media (max-width: 1024px) and (min-width: 807px) {
    width: 48%;
    &:nth-child(2) {
      margin-right: 0px;
    }
  }

  @media (max-width: 806px) {
    width: 100%;
    margin-right: 0px;
  }
}

.date-filter-item {
  max-width: inherit;
  margin-bottom: 0px;
}

.emptyView {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'ProximaNova-Regular', sans-serif;
  color: #fff;
  font-size: 16px;
  padding: 16px;
  &-dark {
    color: #fff;
  }
  &-light {
    color: rgba(30, 30, 30, 1);
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    min-height: 100px;
    overflow-x: hidden;
  }
}

.onlyActivities {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  .switch {
    &-dark {
      border: 0.01px solid map-get($dark, 'theme-button-border');

      &:before {
        background-color: map-get($dark, 'theme-button');
      }
    }

    &-light {
      border: 0.01px solid map-get($light, 'theme-button-border');
      &:before {
        background-color: map-get($light, 'theme-button');
      }
    }
  }

  .icon {
    &-dark {
      border: 0.01px solid map-get($dark, 'theme-button-border');

      &:before {
        background-color: $white;
        color: $white;
        font-size: 24px;
        left: 5px;
        bottom: 3px;
        content: ' ';
      }
    }

    &-light {
      border: 0.01px solid map-get($light, 'theme-button-border');

      &:before {
        background-color: $black;
        color: $black;
        font-size: 24px;
        left: 5px;
        bottom: 3px;
        content: ' ';
      }
    }

    &-selected {
      &:before {
        background-color: #0f62fe !important;
      }
    }
  }
}
