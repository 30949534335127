@import '/src/theme/constants/colors';

.currency {
  &-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border-radius: 12px;
    font-family: 'ProximaNova-Medium', sans-serif;
    margin-right: 20px;
    height: 40px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      min-width: 180px;
    }

    &-dark {
      background-color: map-get($dark, 'currency-box-background');
      color: hexToRgba($white, 0.87);
    }

    &-light {
      background-color: map-get($light, 'currency-box-background');
      color: hexToRgba($black, 0.87);
    }
  }

  &-symbol {
    font-size: 24px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      font-size: 19;
    }
    margin-right: 22px;
    font-family: 'ProximaNova-Medium', sans-serif;
  }

  &-balance {
    font-size: 20px;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      font-size: 15px;
    }
    font-family: 'ProximaNova-Medium', sans-serif;
  }
}
